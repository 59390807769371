import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { ReactComponent as NotesIcon } from "../../../../../../../../assets/icons/icon-notes.svg";
import { ReactComponent as ClipboardTextIcon } from "../../../../../../../../assets/icons/icon-clipboard-text.svg";
import FilsIllustraion from "../../../../../../../../assets/images/files-illustraion.png";

import { useAppSelector } from "../../../../../../../../redux/hooks";
import { getChaptersList, getCourseDetails, getInactiveCourseList, setNewSubjectInfo, setSubjectDetails } from "../../../../../../../../redux/pages/courseSlice";

const CourseList = () => {

   const [newSubjectData, setNewSubjectData] = useState<any>("");
   const [inactiveSubjectData, setInactiveSubjectData] = useState<any>([]);
   const [selectedSubjectId, setSelectedSubjectId] = useState<any>("");

   //redux
   const dispatch = useDispatch<any>();
   const newSubject = useAppSelector((state) => state.course.newSubject);
   const inactiveSubjects = useAppSelector((state) => state.course.inactiveCourseList);
   const subjectDetails = useAppSelector((state) => state.course.subjectDetails);
   const chaptersList = useAppSelector((state) => state.course.chaptersList);

   useEffect(() => {
      dispatch(getInactiveCourseList());
   }, []);

   useEffect(() => {
      if (newSubject) {
         setNewSubjectData(newSubject);
         dispatch(getChaptersList(newSubject?.id));
      }
   }, [newSubject]);

   useEffect(() => {
      if (inactiveSubjects) {
         setInactiveSubjectData(inactiveSubjects);
      }
   }, [inactiveSubjects]);

   useEffect(() => {
      if (subjectDetails) {
         dispatch(setNewSubjectInfo(subjectDetails));
         dispatch(setSubjectDetails(""));
      }
   }, [subjectDetails]);

   useEffect(() => {
      if (selectedSubjectId) {
         dispatch(getCourseDetails(selectedSubjectId));
      } else {
         dispatch(setSubjectDetails(""));
      }
   }, [selectedSubjectId]);

   const onSelectSubject = useCallback((item: any) => {
      if (item && item.id) {
         setSelectedSubjectId(item?.id);
      }
   }, []);

   const emptyTemplate = (message: string) => {
      return (
         <div className="empty-msg">
            <div className="files-illustraion mb-3">
               <img
                  src={FilsIllustraion}
                  className="img-fluid"
                  alt="empty illustraion"
               />
            </div>
            <p className="description"> {message} </p>
         </div>
      )
   };

   return (
      <div className="col-xl-5">
         <div className="card h-100">
            {newSubjectData || inactiveSubjectData?.length ? <div className="card-title-wrapper">
               <h3 className="card-title">{
                  newSubjectData ? newSubjectData?.name
                     : inactiveSubjectData && inactiveSubjectData?.length ? "Previously added subjects" : ""}
               </h3>
               <div className="line"></div>
            </div> : null}

            <div className="card-inner-scroll-area">
               {newSubjectData ?
                  <div className="pe-2">
                     {chaptersList && chaptersList?.length ?
                        <>
                           {chaptersList?.map((item: any, key: any) => (
                              <button key={key} className="list-card" >
                                 <span className="me-2">
                                    <NotesIcon />
                                 </span>
                                 <p className="title">{item?.name}</p>
                              </button>))}
                        </>
                        :
                        <div className="d-flex align-items-center justify-content-center empty-msg-wrap">
                           {emptyTemplate("Add a Chapters and Tests in next steps")}
                        </div>}
                  </div>
                  :
                  inactiveSubjectData && inactiveSubjectData?.length ?
                     <div className="pe-2">
                        {inactiveSubjectData?.map((item: any, key: any) => (
                           <button key={key} className="list-card" onClick={() => onSelectSubject(item)}>
                              <span className="me-2">
                                 <ClipboardTextIcon />
                              </span>
                              <p className="title">{item?.name}</p>
                           </button>
                        ))}
                     </div>
                     :
                     <div className="d-flex align-items-center justify-content-center empty-msg-wrap">
                        {emptyTemplate("Create a subject and see contents here")}
                     </div>
               }
            </div>
         </div>
      </div>
   )
};

export default CourseList;