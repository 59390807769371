import { useEffect, useState } from "react";
import moment from "moment";

import "./offers.scss";

// icons
import { ReactComponent as AddCirclePlusIcon } from "../../../assets/icons/icon-add-circle.svg";
import { ReactComponent as SortIcon } from "../../../assets/icons/icon-sort.svg";
import { ReactComponent as PaginationLeftArrowIcon } from "../../../assets/icons/pagination-arrow-left.svg";
import { ReactComponent as PaginationRightArrowIcon } from "../../../assets/icons/pagination-arrow-right.svg";
import { ReactComponent as DotsVerticalIcon } from "../../../assets/icons/icon-dots-vertical.svg";
import { ReactComponent as UsersIcon } from "../../../assets/icons/icon-users.svg";
import { ReactComponent as EditIcon } from "../../../assets/icons/icon-edit.svg";
import { ReactComponent as TrashIcon } from "../../../assets/icons/icon-trash.svg";
import { ReactComponent as CopyIcon } from "../../../assets/icons/icon-copy.svg";

// images
import EmptyOffersImage from "../../../assets/images/empty-offer-illustration.svg";
import EmptyCouponsImage from "../../../assets/images/empty-coupons-illustration.svg";
import { tr } from "date-fns/locale";
import CreateOffersModal from "./components/CreateOfferModal/CreateOffersModal";
import { useDispatch } from "react-redux";
import { deleteOffers, getOffersList } from "../../../redux/pages/offersSlice";
import { useAppSelector } from "../../../redux/hooks";
import EditOffersModal from "./components/EditOffersModal/EditOffersModal";
import DeleteModal from "../../../common/components/Modal/DeleteModal/DeleteModal";
import {
  deleteCoupons,
  getCouponsList,
} from "../../../redux/pages/couponsSlice";
import CreateCouponsModal from "../coupens/components/CreateCouponsModal/CreateCouponsModal";
import EditCouponsModal from "../coupens/components/EditCouponsModal/EditCouponsModal";

const Offers = () => {
  const [showCouponModal, setShowCouponModal] = useState(false);
  const [showEditCoupon, setShowEditCoupon] = useState(false);
  const [showOfferModal, setShowOfferModal] = useState(false);
  const [showEditOffer, setShowEditOffer] = useState<any>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<any>(false);
  const [programDetails, setProgramDetails] = useState<any>([]);
  const [offerList, setOfferList] = useState<any>([]);
  const [couponList, setCouponList] = useState<any>([]);
  const [couponPagination, setCouponPagination] = useState<any>([]);
  const [offerpagination, setOfferPagination] = useState<any>([]);
  const [offerId, setOfferId] = useState(null);
  const [couponId, setCouponId] = useState(null);

  const offersInfo = useAppSelector((state) => state.offer?.offersList);
  const coupenInfo = useAppSelector((state) => state.coupen?.couponsList);


   useEffect(() => {
    dispatch(getOffersList());
    dispatch(getCouponsList());
  }, []);

  const handleCouponEdit = (item: any) => {
    setShowEditCoupon(true);
    setProgramDetails(item);
  };

  const handleOfferEdit = (item: any) => {
    setShowEditOffer(true);
    setProgramDetails(item);
  };

  const handleOfferDelete = (id: any) => {
    setShowDeleteModal(true);
    setOfferId(id);
  };

  const handleCouponDelete = (id: any) => {
    setShowDeleteModal(true);
    setCouponId(id);
  };

  const dispatch = useDispatch<any>();

  const onDeleteProgram = () => {
    if (offerId) {
      console.log(offerId)
      const data = { id: offerId };
      dispatch(deleteOffers(data, setShowDeleteModal, setOfferId));
    }
    if (couponId) {
      const data = { id: couponId };
      dispatch(deleteCoupons(data, setShowDeleteModal, setCouponId));
    }
  };

  const onCancelDeleteAction = () => {
    setOfferId(null);
    setCouponId(null);
  };

  useEffect(() => {
    if (offersInfo) {
      setOfferList(offersInfo?.data);
      setOfferPagination(offersInfo?.links);
    }
  }, [offersInfo]);

  useEffect(() => {
    if (coupenInfo) {
      setCouponList(coupenInfo?.data);
      setCouponPagination(coupenInfo?.links);
    }
  }, [coupenInfo]);

  return (
    <>
      <div className="offers-wrapper">
        {/* title section */}
        <div className="d-flex justify-content-between flex-wrap title-wrapper mx-4">
          <div>
            <h2 className="title">Offers</h2>
            <p className="desc mb-2">Create offers and coupons</p>
          </div>
        </div>
        {/* tab section */}
        <div className="tab-section mx-4">
          <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
            <li className="nav-item" role="presentation">
              <button
                className="nav-link active"
                id="pills-offers-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-offers"
                type="button"
                role="tab"
                aria-controls="pills-home"
                aria-selected="true"
              >
                Offers
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className="nav-link"
                id="pills-coupons-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-coupons"
                type="button"
                role="tab"
                aria-controls="pills-coupons"
                aria-selected="false"
              >
                Coupons
              </button>
            </li>
          </ul>
          <div className="tab-content" id="pills-tabContent">
            <div
              className="tab-pane fade show active"
              id="pills-offers"
              role="tabpanel"
              aria-labelledby="pills-offers-tab"
              tabIndex={0}
            >
              <div className="">
                {/* control section */}
                <div className="d-flex control-section justify-content-between pb-3 ">
                  <div className="dropdown sort-dropdown">
                    <button
                      className="control-btn dropdown-toggle"
                      // type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <span className="me-2">
                        <SortIcon />
                      </span>
                      Sort
                    </button>

                    <ul className="dropdown-menu">
                      <li>
                        <a className="dropdown-item" href="#">
                          Name
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="#">
                          Created
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="#">
                          Last modified
                        </a>
                      </li>
                    </ul>
                  </div>
                  <a
                    onClick={() => setShowOfferModal(true)}
                    className="btn btn-primary"
                  >
                    <span className="me-1">
                      <AddCirclePlusIcon />
                    </span>
                    Create Offer
                  </a>
                </div>

                <div className="table-wrapper offers-table">
                  <table className="table table-hover table-borderless table-responsive">
                    <thead>
                      <tr>
                        <th scope="col">Title</th>
                        <th scope="col">Discount</th>
                        <th scope="col">Validity</th>
                        <th scope="col">Status</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {offerList?.length > 0 ? (
                        offerList?.map((item: any, index: any) => {
                          return (
                            <tr key={index} className="table-row">
                              <td>
                                <p className="text">{item.title}</p>
                              </td>
                              <td>
                                {item.descount_type == "percentage" ? (
                                  <p className="text">{item.descount_value}%</p>
                                ) : (
                                  <p className="text">{item.descount_value}</p>
                                )}
                              </td>
                              <td>
                                <p className="text">
                                  {moment(item.valid_to).format("YYYY-MM-DD")}
                                </p>
                              </td>
                              <td>
                                {/* add active class for status active, and expired for expired status */}
                                <p className=" active">{item.status}</p>
                                {/* <p className="status expired">Expired</p> */}
                              </td>
                              <td>
                                <div className="dropdown more-dropdown">
                                  <button
                                    className="dropdown-toggle more-dropdown-btn dropdown-arrow-hide"
                                    // type="button"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                  >
                                    <DotsVerticalIcon />
                                  </button>

                                  <ul className="dropdown-menu dropdown-menu-end">
                                    <li>
                                      <button
                                        onClick={() => handleOfferEdit(item)}
                                        className="dropdown-item"
                                      >
                                        <span className="me-2">
                                          <EditIcon />
                                        </span>
                                        Edit
                                      </button>
                                    </li>
                                    <li>
                                      <button
                                        className="dropdown-item text-danger"
                                        onClick={() =>
                                          handleOfferDelete(item.id)
                                        }
                                        // data-bs-toggle="modal"
                                        // data-bs-target="#staticBackdrop"
                                      >
                                        <span className="me-2">
                                          <TrashIcon />
                                        </span>
                                        Delete
                                      </button>
                                    </li>
                                  </ul>
                                </div>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr className="empty-wrapper">
                          <td colSpan={5} className="mx-auto">
                            <div className="empty-container p-5">
                              <img
                                src={EmptyOffersImage}
                                alt="empty offer illustration"
                              />
                              <p className="description">
                                No discounts created yet!!
                              </p>
                            </div>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
                {/* pagination section */}
                <div className="d-flex align-items-center justify-content-center pt-3 pb-3">
                  <nav aria-label="...">
                    <ul className="pagination mb-0">
                      <li className="page-item disabled me-4">
                        <a className="page-link" href={offersInfo?.pre_page_url}>
                          {offerpagination?.[0]?.label}
                          <span className="me-2">
                            <PaginationLeftArrowIcon />
                          </span>
                        </a>
                      </li>

                      {offerpagination?.slice(1, -1).map((pages: any, index: number) => (
                          <li className="page-item" key={index}>
                            <a className="page-link" href={offersInfo?.next_page_url}>
                              {pages?.label}
                            </a>
                          </li>
                        ))}

                      <li className="page-item ms-4">
                        <a className="page-link" href="#">
                          {offerpagination?.[offerpagination.length - 1]?.label}
                          <span className="ms-2">
                            <PaginationRightArrowIcon />
                          </span>
                        </a>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
            <div
              className="tab-pane fade"
              id="pills-coupons"
              role="tabpanel"
              aria-labelledby="pills-coupons"
              tabIndex={0}
            >
              <div className="">
                {/* control section */}
                <div className="d-flex control-section justify-content-between pb-3 ">
                  <div className="dropdown sort-dropdown">
                    <button
                      className="control-btn dropdown-toggle"
                      // type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <span className="me-2">
                        <SortIcon />
                      </span>
                      Sort
                    </button>

                    <ul className="dropdown-menu">
                      <li>
                        <a className="dropdown-item" href="#">
                          Name
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="#">
                          Created
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="#">
                          Last modified
                        </a>
                      </li>
                    </ul>
                  </div>
                  <a
                    onClick={() => setShowCouponModal(true)}
                    className="btn btn-primary"
                  >
                    <span className="me-1">
                      <AddCirclePlusIcon />
                    </span>
                    Create Coupon
                  </a>
                </div>

                <div className="table-wrapper offers-table">
                  <table className="table table-hover table-borderless table-responsive">
                    <thead>
                      <tr>
                        <th scope="col">Coupon code</th>
                        <th scope="col">Discount</th>
                        <th scope="col">Validity</th>
                        <th scope="col">Status</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {couponList?.length > 0 ? (
                        couponList?.map((item: any) => {
                          return (
                            <tr className="table-row">
                              <td>
                                <div className="d-flex">
                                  <p className="text">{item.coupon_code}</p>
                                  <button className="icon-btn ms-2">
                                    <span>
                                      <CopyIcon />
                                    </span>
                                  </button>
                                </div>
                              </td>
                              <td>
                                {item.descount_type == "percentage" ? (
                                  <p className="text">{item.descount_value}%</p>
                                ) : (
                                  <p className="text">{item.descount_value}</p>
                                )}
                              </td>
                              <td>
                                <p className="text">
                                  {moment(item.valid_to).format("YYYY-MM-DD")}
                                </p>
                              </td>
                              <td>
                                {/* add active class for status active, and expired for expired status */}
                                <p className="status active">{item.status}</p>
                                {/* <p className="status expired">Expired</p> */}
                              </td>
                              <td>
                                <div className="dropdown more-dropdown">
                                  <button
                                    className="dropdown-toggle more-dropdown-btn dropdown-arrow-hide"
                                    // type="button"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                  >
                                    <DotsVerticalIcon />
                                  </button>

                                  <ul className="dropdown-menu dropdown-menu-end">
                                    <li>
                                      <button
                                        onClick={() => handleCouponEdit(item)}
                                        className="dropdown-item"
                                      >
                                        <span className="me-2">
                                          <EditIcon />
                                        </span>
                                        Edit
                                      </button>
                                    </li>
                                    <li>
                                      <button
                                        className="dropdown-item text-danger"
                                        // data-bs-toggle="modal"
                                        // data-bs-target="#staticBackdrop"
                                        onClick={() =>
                                          handleCouponDelete(item.id)
                                        }
                                      >
                                        <span className="me-2">
                                          <TrashIcon />
                                        </span>
                                        Delete
                                      </button>
                                    </li>
                                  </ul>
                                </div>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr className="empty-wrapper">
                          <td colSpan={5} className="mx-auto">
                            <div className="empty-container p-5">
                              <img
                                src={EmptyCouponsImage}
                                alt="empty coupon illustration"
                              />
                              <p className="description">
                                No discounts created yet!!
                              </p>
                            </div>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
                {/* pagination section */}
                <div className="d-flex align-items-center justify-content-center pt-3 pb-3">
                  <nav aria-label="...">

                    <ul className="pagination mb-0">
                      <li className="page-item disabled me-4">
                        <a className="page-link" href={coupenInfo?.pre_page_url}>
                          <span className="me-2">
                            <PaginationLeftArrowIcon />
                          </span>
                          {couponPagination?.[0]?.label}
                        </a>
                      </li>

                      {couponPagination?.slice(1, -1).map((pages: any, index: number) => (
                          <li className="page-item" key={index}>
                            <a className="page-link" href={pages?.url}>
                              {pages?.label}
                            </a>
                          </li>
                        ))}
                     
                      <li className="page-item ms-4">
                        <a className="page-link" href={coupenInfo?.next_page_url}>
                          {couponPagination?.[(couponPagination.length)-1]?.label}
                          <span className="ms-2">
                            <PaginationRightArrowIcon />
                          </span>
                        </a>
                      </li>
                    </ul>

                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* uncomment this component to see the offers modal */}
      {/* <CreateOffersModal /> */}
      {showOfferModal && (
        <CreateOffersModal showAll={true} showModal={setShowOfferModal} />
      )}
      {showEditOffer && (
        <EditOffersModal
          programInfo={programDetails}
          showAll={true}
          showModal={setShowEditOffer}
        />
      )}
      {showDeleteModal && (
        <DeleteModal
          showModal={setShowDeleteModal}
          onConfirm={onDeleteProgram}
          onCancel={onCancelDeleteAction}
          message={`The offer ${
            offerList?.find((item: any) => item.id === offerId)?.name
          } will be permanently deleted.`}
        />
      )}
      {showCouponModal && (
        <CreateCouponsModal showAll={true} showModal={setShowCouponModal} />
      )}
      {showEditCoupon && (
        <EditCouponsModal
          programInfo={programDetails}
          showAll={true}
          showModal={setShowEditCoupon}
        />
      )}
      {showDeleteModal && (
        <DeleteModal
          showModal={setShowDeleteModal}
          onConfirm={onDeleteProgram}
          onCancel={onCancelDeleteAction}
          message={`The coupon ${
            couponList?.find((item: any) => item.id === couponId)?.name
          } will be permanently deleted.`}
        />
      )}
       
    </>
  );
};

export default Offers;
