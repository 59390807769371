import React, { useEffect, useState } from "react";

import "./EditCouponsModal.scss";

// icons
import { ReactComponent as SearchIcon } from "../../../../../assets/icons/icon-search-normal.svg";
import { ReactComponent as CalendarIcon } from "../../../../../assets/icons/icon-calendar.svg";
import { ReactComponent as RefreshIcon } from "../../../../../assets/icons/icon-refresh.svg";

import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { getCourseList } from "../../../../../redux/pages/courseSlice";
import { useAppSelector } from "../../../../../redux/hooks";
import moment from "moment";
import { couponSchema } from "../../../../../validations/couponValidation";
import { updateCoupons } from "../../../../../redux/pages/couponsSlice";

interface EditCoupon {
  showAll? : boolean;
  showModal: (value: boolean) => void;
  programInfo: any;
}

const EditCouponsModal : React.FC<EditCoupon> = (props) => {

  const { 
    showAll = false,
    showModal = () => {},
    programInfo = () => {}
} = props;

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(couponSchema),

  });


  const [type,setType] = useState("")
  const [fromDate,setFromDate] = useState("")

  useEffect(() => {
    if (programInfo) {
      setValue("coupon_code", programInfo?.coupon_code);
      setValue("descount_type", programInfo?.descount_type);
      setValue("descount_value", programInfo?.descount_value);
      setValue("valid_from", programInfo?.valid_from);
      setValue("valid_to", programInfo?.valid_to);
      setValue("status", programInfo?.status);
    }
  }, [programInfo]);
  

 const dispatch = useDispatch<any>()

  const onEditCouponSubmit = (data: any) => {
    if (data) {
      const submitData = {
        ...data,
        valid_from: moment(data?.valid_from).format("YYYY-MM-DD"),
        valid_to: moment(data?.valid_to).format("YYYY-MM-DD"),
        id: programInfo.id
      };
      dispatch(updateCoupons(submitData, showAll, showModal));
    }
  };


  return (
    <div
      className="as-modal create-offers-modal"
      id="add-role-modal"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex={-1}
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog-wrapper">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Edit Coupon</h4>
            </div>
            <div className="modal-body">

              
              {/* for course selection */}

              <form 
               onSubmit={handleSubmit(onEditCouponSubmit)}
               > 
               <div className="form-wrapper">
                <div className="sub-section mb-3">
                  <h5 className="sub-title">Coupon Code</h5>
                  <div className="form-group">
                    <div className="d-flex">
                    <input
                          type="text"
                          className="form-group-control fw-bold"
                          {...register("coupon_code")}
                          name="coupon_code"
                          placeholder="Coupon Code"
                        />
                        <p className="validation-text">{errors?.coupon_code?.message?.toString()}</p>
                      {/* <button className="ms-2">
                        <RefreshIcon />
                      </button>          */}
                    </div>
                  </div>
                </div>

                <div className="sub-section mb-3">
                  <h5 className="sub-title">Discount</h5>
                  <div className="d-flex align-items-center gap-4 mb-2">
                    <div className="form-radio-btn">
                    <input
                            type="radio"
                            id="radio1"
                            checked={watch("descount_type") === "percentage"}
                            onClick={() => setValue("descount_type", "percentage")}
                          />
                      <label htmlFor="radio1">Percentage</label>
                      <p className="validation-text" style={{color:"#d43d35",fontSize:"1vw"}}>{errors?.descount_type?.message?.toString()}</p>
                    </div>
                    <div className="form-radio-btn">
                    <input
                            type="radio"
                            id="radio2"
                            checked={watch("descount_type") === "amount"}
                            onClick={() => setValue("descount_type", "amount")}
                          />
                      <label htmlFor="radio2">Value</label>
                    </div>
                  </div>
                  <div className="form-group mt-3">
                      {watch("descount_type") === "percentage" ?
                        <>
                          <h5 className="form-group-label">Discount percentage</h5>
                          <div className="icon-form-control">
                            {/* <div className="start-icon">
                              <a>%</a>
                            </div> */}
                            <input
                              type="text"
                              className="form-group-control fw-bold"
                              placeholder="Enter discount percentage"
                              {...register("descount_value")}
                            />
                            <p className="validation-text">{errors.descount_value?.message}</p>
                          </div>
                        </>
                        :
                        <>
                          <h5 className="form-group-label">Discount value</h5>
                          <div className="icon-form-control">
                            <input
                              type="text"
                              className="form-group-control fw-bold"
                              placeholder="Enter discount value"
                              {...register("descount_value")}
                            />
                            <p className="validation-text">{errors.descount_value?.message}</p>
                          </div>
                        </>}
                    </div>
                </div>

                <div className="sub-section mb-5">
                  <h5 className="sub-title">Validity</h5>
                  <div className="row">
                    <div className="col-5">
                      <div className="form-group">
                        <h5 className="form-group-label">Start date</h5>
                        <div className="icon-form-control">
                          <div className="start-icon">
                          </div>
                          <input
                              type="date"
                              value={moment(watch("valid_from")).format("YYYY-MM-DD")}
                              className="form-group-control"
                              placeholder="Start date"
                              {...register("valid_from")}
                              min="2000-01-01"
                              max="2500-12-31"
                            />
                          <p className="validation-text">{errors?.valid_from?.message?.toString()}</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-1">
                      <div className="d-flex align-items-end h-100">
                        <p className="desc-text mb-2">To</p>
                      </div>
                    </div>
                    <div className="col-5">
                      <div className="form-group">
                        <h5 className="form-group-label">End date</h5>
                        <div className="icon-form-control">
                          <div className="start-icon">
                          </div>
                          <input
                              type="date"
                              value={moment(watch("valid_to")).format("YYYY-MM-DD")}
                              className="form-group-control"
                              placeholder="End date"
                              {...register("valid_to")}
                              min="2000-01-01"
                              max="2500-12-31"
                            />
                          <p className="validation-text">{errors?.valid_to?.message?.toString()}</p>
                        </div>
                      </div>
                      
                    </div>
                    <div className="form-group mt-3">
                        <h5 className="form-group-label mb-2">Status</h5>
                        <div className="d-flex align-items-center gap-4 mb-2">
                          <div className="form-radio-btn">
                            <input
                              type="radio"
                              checked={watch("status") === "active"}
                              onClick={() => setValue("status", "active")}
                            />
                            <label htmlFor="radio1">Active</label>
                          </div>
                          <div className="form-radio-btn">
                            <input
                              type="radio"
                              checked={watch("status") === "inactive"}
                              onClick={() => setValue("status", "inactive")}
                            />
                            <label htmlFor="radio2">Inactive</label>
                          </div>
                        </div>
                        <p className="validation-text">{errors.status?.message}</p>
                      </div>
                  </div>
                </div>

                <div className="d-flex btn-wrapper justify-content-between mt-3">
                  <button
                    type="button"
                    className="btn btn-sm btn-outline-danger"
                    onClick={() => showModal(false)}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="btn btn-sm btn-primary close-modal-btn"
                  >
                    Save changes
                  </button>
                </div>
              </div>
               </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditCouponsModal;
