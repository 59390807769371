import { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import Chapter from "./chapter/chapter";
import Tests from "./tests/test";
import { setSelectedItemInfo } from "../../../../../../../../redux/pages/courseSlice";

interface chapterInfo{ 
   setSelected?: (data: any) => void;
};

const ChapterMain = forwardRef<any, chapterInfo>((props, ref) => {
   const {
      setSelected = () => {}
    } = props;

   const chapterPageRef = useRef<any>();
   const testPageRef = useRef<any>();

   const [selectedItem, setSelectedItem] = useState<string>("chapter");

   const dispatch = useDispatch<any>();

   useImperativeHandle(ref, () => ({
      onSaveTrigger: () => {
         handleSaveClick();
      }
   }));

   useEffect(() => {
      dispatch(setSelectedItemInfo(null));
   },[selectedItem]);

   const handleSaveClick = () => {
      if (selectedItem === "chapter") {
         chapterPageRef?.current?.onSaveTrigger();
      } else {
         testPageRef?.current?.onSaveTrigger();
      }
   };

   const formTemplate = useCallback(() => {
      switch (selectedItem) {
         case "chapter": return (<Chapter ref={chapterPageRef}/>);
         case "test": return (<Tests ref={testPageRef}/>);
      }
   }, [selectedItem]);

   return (
      <>
         <div className="card-title-wrapper">
            <h3 className="card-title">{selectedItem === "chapter" ? "Chapter " : "Test "}Creation</h3>
            <div className="d-flex align-items-center gap-4 mb-3">
               <div className="form-radio-btn">
                  <input
                     type="radio"
                     name="radioDefault"
                     id="radio1"
                     checked={selectedItem === "chapter"}
                     onChange={() => {
                        setSelectedItem("chapter");
                        setSelected("chapter");
                     }}
                  />
                  <label htmlFor="radio1">Chapter</label>
               </div>
               <div className="form-radio-btn">
                  <input
                     type="radio"
                     name="radioDefault"
                     id="radio2"
                     checked={selectedItem === "test"}
                     onChange={() => {
                        setSelectedItem("test");
                        setSelected("test");
                     }}
                  />
                  <label htmlFor="radio2">Test</label>
               </div>
            </div>
            <div className="line"></div>
         </div>
         {formTemplate()}
      </>
   )
});

export default ChapterMain;