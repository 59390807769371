import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AppThunk } from "../store";
import { axiosInstance } from "../interceptors/axiosConfig";
import { showNotification } from "../../common/components/Toaster/Toast";

export interface subCategoryState {
    errors: any;
    isLoading: boolean;
    subProgramList: any;
    allsubProgramList: any;
}

const initialState: subCategoryState = {
    errors: null,
    isLoading: false,
    subProgramList: [],
    allsubProgramList:[],
};

const  subCategorySlice = createSlice({
    name: "subProgram",
    initialState,
    reducers: {
        setErrors: (state, { payload }: PayloadAction<any>) => {
            state.errors = payload;
        },
        setLoading: (state, { payload }: PayloadAction<any>) => {
            state.isLoading = payload;
        },
        setSubProgramsList: (state, { payload }: PayloadAction<any>) => {
            state.subProgramList = payload;
        },
        setAllSubProgramsList: (state, { payload }: PayloadAction<any>) => {
            state.allsubProgramList = payload;
        },            
    }
});

export const getSubProgramsList = (program_id:any): AppThunk => async (dispatch) => {
    dispatch(setLoading(true));
    try {
        let URL = '/subprogram/index';
        const response = await axiosInstance({ data: { url: URL, method: 'post', data:program_id }, token: true });
        if (response.data) {
            if (response.data.status && response.data.status === 'success') {
                dispatch(setSubProgramsList(response.data));
                dispatch(setLoading(false));
            }
        }
    } catch (error: any) {
        dispatch(setLoading(false));
        dispatch(setErrors(error?.response))
    }
};


export const getAllSubProgramsList = (): AppThunk => async (dispatch) => {
    dispatch(setLoading(true));
    try {
        let URL = '/subprogram/index';
        const response = await axiosInstance({ data: { url: URL, method: 'post', data:null }, token: true });
        if (response.data) {
            if (response.data.status && response.data.status === 'success') {
                dispatch(  setAllSubProgramsList(response.data));
                dispatch(setLoading(false));
            }
        }
    } catch (error: any) {
        dispatch(setLoading(false));
        dispatch(setErrors(error?.response))
    }
};


export const updateSubProgram = (data: any, setShowEditModal: any): AppThunk => async (dispatch) => {
    dispatch(setLoading(true));
    try {
        console.log(data,"data")
        let URL = '/subprogram/update';
        const response = await axiosInstance({ data: { url: URL, method: 'post', data: data }, token: true });
        if (response.data) {
            if (response.data.status && response.data.status === 'success') {
                dispatch( getAllSubProgramsList());
                dispatch(setLoading(false));
                setShowEditModal(false);
                showNotification({
                    message: "sub-Category updated successfully",
                    theme: "light",
                    type: "success"
                });
            }
        }
    } catch (error: any) {
        dispatch(setLoading(false));
        dispatch(setErrors(error?.response))
    }
};


export const deleteSubProgram = (data: any, setShowDeleteModal: any, setCategoryId: any): AppThunk => async (dispatch) => {
    dispatch(setLoading(true));
    try {
        let URL = '/subprogram/delete';
        const response = await axiosInstance({ data: { url: URL, method: 'post', data: data }, token: true });
        if (response.data) {
            if (response.data.status && response.data.status === 'success') {
                dispatch( getAllSubProgramsList());
                dispatch(setLoading(false));
                setShowDeleteModal(false);
                setCategoryId(null);
                showNotification({
                    message: "sub-Category deleted successfully",
                    theme: "light",
                    type: "success"
                });
            }
        }
    } catch (error: any) {
        dispatch(setLoading(false));
        dispatch(setErrors(error?.response))
    }
};

export const {
    setErrors,
    setLoading,
    setSubProgramsList,
    setAllSubProgramsList,
} =  subCategorySlice.actions;

export default subCategorySlice.reducer;