import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { ReactComponent as TrashIcon } from "../../../../../../../../assets/icons/icon-trash.svg";
import { ReactComponent as EditSquareIcon } from "../../../../../../../../assets/icons/icon-edit-square.svg";
import { ReactComponent as CaretUpIcon } from "../../../../../../../../assets/icons/icon-caret-up.svg";
import { ReactComponent as CaretDownIcon } from "../../../../../../../../assets/icons/icon-caret-down.svg";
import clipBoardImage from "../../../../../../../../assets/images/clipboard-img.svg";
import { createQuestionSchema } from "../../../../../../../../validations/courseValidations";
import { showNotification } from "../../../../../../../../common/components/Toaster/Toast";
import { useAppSelector } from "../../../../../../../../redux/hooks";
import { useDispatch } from "react-redux";
import { addNewTestQuestion, deleteQuestion, getQuestionsList, updateQuestion } from "../../../../../../../../redux/pages/courseSlice";
import DeleteModal from "../../../../../../../../common/components/Modal/DeleteModal/DeleteModal";

interface testDetails {
   selectedTest: any;
}

const SubjectTest: React.FC<testDetails> = (props) => {
   const {
      selectedTest = "",
   } = props;

   const {
      register,
      setValue,
      watch,
   } = useForm({
      resolver: yupResolver(createQuestionSchema),
      defaultValues: {
         question: "",
         answer: "",
         point: 1,
         optionValue: "",
      }
   });

   const [questions, setQuestions] = useState<any>([]);
   const [selectedItem, setSelectedItem] = useState<any>(null);
   const [isEditMode, setIsEditMode] = useState<boolean>(false);
   const [isAddMode, setIsAddMode] = useState<boolean>(false);
   const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
   const [options, setOptions] = useState<any>([]);

   const dispatch = useDispatch<any>();
   const questionsInfo = useAppSelector((state) => state.course.questionsList);


   useEffect(() => {
      if (selectedTest) {
         const body = { test_id: selectedTest };
         dispatch(getQuestionsList(body));
      }
   }, [selectedTest]);

   useEffect(() => {
      if (questionsInfo) {
         setQuestions([...questionsInfo]);
      }
   }, [questionsInfo]);

   useEffect(() => {
      if (!isEditMode && isAddMode) {
         setValue("question", "");
         setValue("point", 1);
         setValue("answer", "");
         setOptions([]);
      }
   }, [isAddMode, isEditMode]);

   useEffect(() => {
      if (selectedItem && isEditMode) {
         setValue("question", selectedItem?.question);
         setValue("point", +selectedItem?.mark);
         setValue("answer", selectedItem?.answer);
         setOptions([...selectedItem?.options]);
      }
   }, [selectedItem, isEditMode]);

   const incrementHandler = () => {
      if (+watch("point") !== 4) {
         setValue("point", +watch("point") + 1);
      } else {
         showNotification({
            message: "Maximum point should be 4",
            theme: "light",
            type: "warn"
         });
      }
   };

   const decrementHandler = () => {
      if (+watch("point") !== 1) {
         setValue("point", +watch("point") - 1);
      } else {
         showNotification({
            message: "Minimum point should be 1",
            theme: "light",
            type: "warn"
         });
      }
   };

   const addButtonHandler = () => {
      const newItem = { question: "", option: [], answer: "", point: 1 };
      setQuestions((prev: any) => ([...prev, newItem]));
      setSelectedItem(newItem);
      setIsEditMode(false);
      setIsAddMode(true);
   };

   const onEditBtnClick = (item: any) => {
      setSelectedItem(item);
      setIsEditMode(true);
      setIsAddMode(false);
   };

   const onDeleteClicked = (item: any) => {
      if (item) {
         setSelectedItem(item);
         setShowDeleteModal(true);
      }
   };

   const onCheck = (answer: any) => {
      if (answer) {
         setValue("answer", answer);
      }
   };

   const handleOptionAdd = () => {
      if (watch("optionValue") !== "") {
         if (!options?.includes(watch("optionValue"))) {
            const result = [...options, watch("optionValue")];
            setOptions([...result]);
            setValue("optionValue", "");
         } else {
            handleNotification("Option already added");
         }
      } else {
         handleNotification("Add a valid option");
      }
   };

   const onRemoveOption = (idx: any) => {
      const prevOptions = [...options];
      const newOptions = prevOptions?.splice(idx, 1);
      setOptions([...prevOptions]);
      if (newOptions[0] === watch("answer")) {
         setValue("answer", "");
      }
   };

   const handleNotification = (message: string) => {
      showNotification({
         message: `${message}`,
         theme: "light",
         type: "warn"
      });
   };

   const saveBtnHandler = (questionId?: any) => {
      if (watch("question") !== "") {
         if (options && options?.length > 1) {
            if (watch("answer") !== "") {
               const submitData = {
                  test_id: selectedTest,
                  type: "multiple_choice",
                  question: watch("question"),
                  option: [...options],
                  answer: watch("answer"),
                  mark: +watch("point")
               };
               if (isAddMode) {
                  dispatch(addNewTestQuestion(submitData, selectedTest, setIsAddMode));
               } else {
                  const data = { ...submitData, id: questionId };
                  dispatch(updateQuestion(data, selectedTest, setIsEditMode, setSelectedItem));
               }
            } else {
               handleNotification("Select an answer from option");
            }
         } else {
            handleNotification("Add atleast two options for a question");
         }
      } else {
         handleNotification("Question field should not be empty");
      }
   };

   const onDeleteQuestion = () => {
      if (selectedItem) {
         const body = { id: selectedItem?.id };
         dispatch(deleteQuestion(body, selectedTest, setShowDeleteModal, setSelectedItem));
      }
   };

   const onCancelDeleteAction = () => {
      setSelectedItem(null);
      setShowDeleteModal(false);
   };

   const optionsTemplate = () => {
      return (
         <ul className="questions-list-wrap">
            {options?.map((opt: any, idx: any) => (
               <li key={idx}>
                  <div className="option d-flex align-items-center gap-3">
                     <div className="form-radio-btn">
                        <input
                           type="radio"
                           id={opt}
                           value={opt}
                           checked={opt === watch("answer")}
                           onChange={(e) => onCheck(e?.target?.value)} />
                        <label htmlFor={opt}>{opt}</label>
                        <button className="icon-button" onClick={() => onRemoveOption(idx)}>
                           <TrashIcon />
                        </button>
                     </div>
                  </div>
               </li>
            ))}
            {options?.length <= 4 ? <div className="row">
               <div className="col-11 form-group mb-4">
                  <input id="opt-val" {...register("optionValue")} type="text" placeholder="Add option" className="form-group-control" />
               </div>
               <div className="d-flex col-1">
                  <button className="icon-button me-3" onClick={() => handleOptionAdd()}>
                     <EditSquareIcon />
                  </button>
               </div>
            </div> : null}
         </ul>
      );
   };

   const pointTemplate = () => {
      return (
         <div className="points-section d-flex align-items-center mt-3 mb-5 gap-3">
            <h5 className="ques-subtitle">Points</h5>
            <div className="d-flex">
               <input id="point" type="text" value={+watch("point")} className="points-box" />
               <div className="">
                  <button role="button" className="arrow-btn mb-1" onClick={() => incrementHandler()}>
                     <CaretUpIcon />
                  </button>
                  <button role="button" className="arrow-btn" onClick={() => decrementHandler()}>
                     <CaretDownIcon />
                  </button>
               </div>
            </div>
         </div>
      );
   };

   const listTemplate = (item: any) => {
      return (
         <>
            <p className="title">{item?.question}</p>
            <div className="d-flex">
               <button className="icon-button me-3" onClick={() => onEditBtnClick(item)}>
                  <EditSquareIcon />
               </button>
               <button className="icon-button" onClick={() => onDeleteClicked(item)}>
                  <TrashIcon />
               </button>
            </div>
         </>
      );
   };

   return (
      <>
         {selectedTest ?
            <>
               <div className="form-wrapper">
                  <div className="d-flex justify-content-between align-items-center mb-3">
                     <h5 className="ques-subtitle">Questions</h5>
                     {!questions?.length ?
                        <button className="btn btn-link" onClick={() => addButtonHandler()}>Add Question</button>
                        : null}
                  </div>

                  {questions && questions?.length ?
                     <ul className="questions-list-wrap">
                        {questions?.map((item: any, index: any) => (
                           <div key={index}>
                              {item?.id === selectedItem?.id ?
                                 <>
                                    {isEditMode ? <>
                                       <div key={index} className="mb-5">
                                          <div className="form-group mb-4">
                                             <div className="text-end">
                                                <button className="btn btn-link" onClick={() => {
                                                   setSelectedItem(null);
                                                   setIsEditMode(false);
                                                }}> Cancel </button>
                                                <button className="btn btn-link" onClick={() => saveBtnHandler(item?.id)}>Update</button>
                                             </div>
                                             <textarea
                                                {...register("question")}
                                                rows={5}
                                                className="form-group-textarea"
                                                placeholder="Add question"
                                             ></textarea>
                                          </div>
                                          {optionsTemplate()}
                                       </div>
                                       {pointTemplate()}
                                    </> : null}
                                 </>
                                 :
                                 <div className="question-list-item mb-3">
                                    {listTemplate(item)}
                                 </div>}
                           </div>
                        ))}

                        {isAddMode ? <>
                           <div className="mb-5">
                              <div className="form-group mb-4">
                                 <div className="text-end">
                                    <button className="btn btn-link" onClick={() => setIsAddMode(false)}> Cancel </button>
                                    <button className="btn btn-sm icon-button" onClick={() => saveBtnHandler()}>Save</button>
                                 </div>
                                 <textarea
                                    {...register("question")}
                                    rows={5}
                                    className="form-group-textarea"
                                    placeholder="Add question"
                                 ></textarea>
                              </div>
                              {optionsTemplate()}
                           </div>
                           {pointTemplate()}
                        </> : null}
                     </ul>
                     :
                     <div className="d-flex align-items-center flex-column justify-content-center empty-question-list p-4">
                        <div className="img-wrapper mb-4">
                           <img src={clipBoardImage} alt="clipboard image" />
                        </div>
                        <p className="desc">Add new questions to create test</p>
                     </div>
                  }
               </div>

               <div className="mt-auto">
                  <div className="d-flex justify-content-between align-items-center mb-3">
                     <h5 className="ques-subtitle"></h5>
                     {questions?.length && !isAddMode && !isEditMode ?
                        <button className="btn btn-link" onClick={() => addButtonHandler()}>Add Question</button>
                        : null}
                  </div>
               </div>
            </>
            :
            <div className="d-flex align-items-center flex-column justify-content-center empty-question-list p-4">
               <div className="img-wrapper mb-4">
                  <img src={clipBoardImage} alt="clipboard image" />
               </div>
               <p className="desc">Select test to view questions</p>
            </div>}
            
         {showDeleteModal && (
            <DeleteModal
               showModal={setShowDeleteModal}
               onConfirm={onDeleteQuestion}
               onCancel={onCancelDeleteAction}
               message={"Are you sure to delete the question"}
            />)}
      </>
   );
};

export default SubjectTest;