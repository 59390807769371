import ReactDOM from "react-dom/client";
import App from "./App";
import "./assets/styles/custom.scss";
import "./index.scss";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import { Provider } from 'react-redux';
import { store } from "./redux/store";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <Provider store={store}>
    <App />
  </Provider>
);

if (process.env.NODE_ENV !== "development") {
  console.log = () => { };
}

