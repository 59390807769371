import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// import "./CreateSubcategoryModal.scss";
import "../../../category/Category.scss"

import { ReactComponent as CircleCloseIcon } from "../../../../../assets/icons/icon-close-circle.svg";
import { useDispatch } from "react-redux";
import { createProgram } from "../../../../../redux/pages/categorySlice";
import { categorySchema } from "../../../../../validations/categoryValidation";
import { subcategorySchema } from "../../../../../validations/subCategoryValidation";
import { useAppSelector } from "../../../../../redux/hooks";

interface CreateInfo {
  showAll?: boolean;
  showModal: (value: boolean) => void;
}

const CreatesubCategoryModal: React.FC<CreateInfo> = (props) => {
  const { 
    showAll = false,
    showModal = () => {},
} = props;

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(subcategorySchema),
  });

  //redux
  const dispatch = useDispatch<any>();
  const [programList,setProgramList]= useState<any>([])
  const [program_id, setProgram_id] =useState<any>([]);

  const programInfo= useAppSelector ((state) => state.programs.programList);

  const onCategoryFormSubmit = (data: any) => {
    if(data) {
      dispatch(createProgram(data, showAll, showModal));
    }
  };

  useEffect(()=>{
    if(programInfo){
      setProgramList(programInfo?.programs)
    }
      },[programInfo])

  return (
    <div
      className="as-modal create-category-modal"
      id="staticBackdrop"
      aria-hidden="true"
    >
      <div className="modal-dialog-wrapper">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header mb-3">
              <h4 className="modal-title">Create New sub-Category</h4>
              <button
                className="close-button close-modal-btn"
                onClick={() => showModal(false)}
              >
                <CircleCloseIcon />
              </button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleSubmit(onCategoryFormSubmit)}>
                <div className={`category-form-control-wrap mb-3 ${!programList?.length ? "disabled" : ""}`}>
                  <p className="form-label">Category Name</p>
                 
                  <select
                  {...register("program_id")}
                  id="program_id"
                  className={`form-select form-group-dropdown ${errors?.program_id ? "error-border" : ""}`}
                  onChange={(e) => setProgram_id(e.target.value)} >
                  <option value="" disabled> Select Category </option>
                  {programList?.map((data: any, key: number) => (
                     <option key={key} value={data.id} className={"form-group-option"} >{data.name}</option>
                  ))}
               </select>
                  <p className="validation-text">{errors?.program_id?.message?.toString()} </p>
                </div>


                <div className="category-form-control-wrap mb-3">
                  <label className="form-label">New sub-Category Name </label>
                  <input
                    type="text"
                    className="form-group-control"
                    placeholder="Enter sub-category name"
                    {...register("name")}
                    name="name"
                  />
                  <p className="validation-text">
                    {errors.price?.message?.toString()}
                  </p>
                </div>

              

                <div className="category-form-control-wrap mb-3">
                  <label className="form-label">Category Price</label>
                  <input
                    type="text"
                    className="form-group-control"
                    placeholder="Enter category Price in Rupees"
                    {...register("price")}
                    name="price"
                  />
                  <p className="validation-text">
                    {errors.price?.message?.toString()}
                  </p>
                </div>
                <div className="category-form-control-wrap mb-4">
                <label className="form-label">Category Description</label>
                  <textarea
                     {...register("description")}
                     name="description"
                     rows={4}
                     className="form-group-textarea"
                  ></textarea>
               </div>
                <div className="d-flex justify-content-between  mt-4">
                  <button
                    type="button"
                    className="btn btn-sm btn-outline-danger close-modal-btn"
                    onClick={() => showModal(false)}
                  >
                    Cancel
                  </button>
                  <button type="submit" className="btn btn-sm btn-primary">
                    Confirm
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreatesubCategoryModal;
