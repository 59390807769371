import React, { useEffect, useState } from 'react'
import { ReactComponent as EditIcon } from "../../../assets/icons/icon-edit-outline-blue.svg";

import { ReactComponent as ArrowLeftIcon } from "../../../assets/icons/icon-arrow-left.svg";
import { ReactComponent as PaginationLeftArrowIcon } from "../../../assets/icons/pagination-arrow-left.svg";
import { ReactComponent as PaginationRightArrowIcon } from "../../../assets/icons/pagination-arrow-right.svg";
import { ReactComponent as GreenArrowIcon } from "../../../assets/icons/icon-arrow-up-green.svg";
import { ReactComponent as RedArrowIcon } from "../../../../assets/icons/icon-arrow-up-red.svg";
import { ReactComponent as ChevronDownIcon } from "../../../assets/icons/icon-chevron-down.svg";
import { ReactComponent as MailIcon } from "../../../../assets/icons/icon-mail.svg";
import { ReactComponent as LockIcon } from "../../../../assets/icons/icon-lock.svg";
import { ReactComponent as EyeIcon } from "../../../../assets/icons/icon-eye.svg";
import { ReactComponent as EyeSlashedIcon } from "../../../../assets/icons/icon-eye-slash.svg";
import { ReactComponent as UserIcon } from "../../../assets/icons/icon-user.svg";
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../../../redux/hooks';
import { onUpdatePassword } from '../../../../redux/pages/profileSlice';
import { resetPasswordSchema } from '../../../../validations/resetPasswordValidation';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from "react-hook-form";



const ChangePassword = () => {

    const {
        register: registerPassword,
        handleSubmit: handleSubmitPassword,
        formState: { errors: passwordErrors },
     } = useForm({
        resolver: yupResolver(resetPasswordSchema),
     });
  
    const [currentPasswordVisible, setCurrentPasswordVisible] = useState(false);
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
    const [userEmail, setUseremail] = useState<any>();

    const dispatch = useDispatch<any>();
    const profileDataInfo = useAppSelector((state) => state.profile.profileStore);

    const handleCurrentPasswordVisibility = () => {
        setCurrentPasswordVisible(!currentPasswordVisible);
     };
     const handlePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
     };
     const handleConfirmPasswordVisibility = () => {
        setConfirmPasswordVisible(!confirmPasswordVisible);
     };
  
  const onChangePassword = (submitData: any) => {
    if (submitData) {
       dispatch(onUpdatePassword(submitData));
    }
 };

  useEffect(() => {
    if (profileDataInfo && profileDataInfo?.user) {
       setUseremail(profileDataInfo?.user?.email);
    }
 }, [profileDataInfo]);



    return (
    <div className="">
    <div className="card settings-card">
      <h4 className="title mb-1">Change Password</h4>
      <p className="desc">
        To change current password, enter current password
      </p>
      <hr className="line" />
      <div className="card-body-content">

        <form
         onSubmit={handleSubmitPassword(onChangePassword)}>
        <div className="wrapper">
          <div>
            {/* current password */}
            <div>

              <div className="form-control-wrap mb-2">
                <label className="form-label">
                  Current Password
                </label>
                <div className="icon-form-control">
                  <div className="start-icon">
                    <LockIcon />
                  </div>
                  <input
                    type={currentPasswordVisible ? "text" : "password"}
                    className="form-control"
                    placeholder="Enter current password"
                    {...registerPassword("current_password")}
                    name="current_password"
                  />
                  <div className="end-icon">
                    <button type="button" role="button" onClick={handleCurrentPasswordVisibility}
                    >
                    {currentPasswordVisible ? (
                     <EyeIcon />
                        ) : (
                     <EyeSlashedIcon />
                     )}
                   </button>
                  </div>
                </div>
                {passwordErrors?.current_password && (
                    <p className="validation-text">
                        {passwordErrors?.current_password?.message}
                    </p>
                )}
              </div>

              <div className="d-flex justify-content-end ">
                <a className="link-danger">Forgot Password?</a>
              </div>
            </div>

            {/* enter code  */}
            <div className="form-control-wrap mb-2">
              <label className="form-label">Your mail id</label>
              <div className="icon-form-control">
                <div className="start-icon">
                  <MailIcon />
                </div>
                <input type="text" className="form-control"
                  placeholder={userEmail}
                />
              </div>
              <p className="validation-text">  </p>
            </div>

            {/* new password */}
            <div>
              <div className="form-control-wrap mb-2">
                <label className="form-label">New Password</label>
                <div className="icon-form-control">
                  <div className="start-icon">
                    <LockIcon />
                  </div>
                  <input
                    type={passwordVisible ? "text" : "password"}
                    className="form-control"
                    placeholder="Enter new password"
                    {...registerPassword("new_password")}
                    name="new_password"
                  />
                  <div className="end-icon">
                  <button   type="button"  role="button"
                    onClick={handlePasswordVisibility}
                  >
                    {passwordVisible ? (
                     <EyeIcon />
                        ) : (
                     <EyeSlashedIcon />
                     )}
                   </button>
                  </div>
                </div>
                {passwordErrors?.current_password&& (
                  <p className="validation-text">
                    {passwordErrors?.current_password?.message}
                   </p>
                )}
              </div>


              <div className="form-control-wrap mb-2">
                <label className="form-label">
                  Confirm Password
                </label>
                <div className="icon-form-control">
                  <div className="start-icon">
                    <LockIcon />
                  </div>
                  <input
                    type={
                      confirmPasswordVisible ? "text" : "password"
                    }
                    className="form-control"
                    placeholder="Re-enter new password"
                    {...registerPassword("confirm_password")}
                    name="confirm_password"
                  />
                  <div className="end-icon">       
                      <button  type="button"   role="button"
                        onClick={handleConfirmPasswordVisibility}
                      >
                        {confirmPasswordVisible ? (
                            <EyeIcon />
                             ) : (
                            <EyeSlashedIcon />
                                    )}
                      </button>

                  </div>
                </div>
                {passwordErrors?.current_password && (
                  <p className="validation-text">
                    {passwordErrors?.current_password?.message}
                  </p>
                )}
               
              </div>
            </div>
          </div>

          <div className="d-flex justify-content-end">
            <button className="btn btn-sm btn-outline-primary">
              Next
            </button>
            <button className="btn btn-sm btn-primary" type='submit' role='button'>
              Change password
            </button>
          </div>
        </div>
        </form>
      </div>
    </div>
    
  </div>
    )
}

export default ChangePassword
