import React, { useEffect, useState } from "react";

import "./modal-styles.scss";

// icons
import { ReactComponent as SearchIcon } from "../../../../../assets/icons/icon-search-normal.svg";
import { ReactComponent as CalendarIcon } from "../../../../../assets/icons/icon-calendar.svg";
import { ReactComponent as RefreshIcon } from "../../../../../assets/icons/icon-refresh.svg";
import { offerSchema } from "../../../../../validations/offersValidation";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { getCourseList } from "../../../../../redux/pages/courseSlice";
import { useAppSelector } from "../../../../../redux/hooks";
import moment from "moment";
import { createCoupons } from "../../../../../redux/pages/couponsSlice";
import { couponSchema } from "../../../../../validations/couponValidation";


interface CreateCoupon {
  showAll? : boolean;
  showModal: (value: boolean) => void;
}

const CreateCouponsModal : React.FC<CreateCoupon> = (props) => {

  const { 
    showAll = false,
    showModal = () => {},
} = props;

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(couponSchema),
  });

  // const [next,setNext] = useState(1)
  // const [program,setProgram] = useState<any>("")
  // const [title,setTitle] = useState<any>([])
  const [type,setType] = useState("")
  const [fromDate,setFromDate] = useState("")

  // const courseList = useAppSelector((state) => state.course.courseList);


  // useEffect(()=>{
  //   dispatch(getCourseList())
  // },[])

 const dispatch = useDispatch<any>()

 useEffect(()=>{
  setValue("descount_type", "percentage");
  setValue("status", "active");
},[]);

  const onCouponFormSubmit = (data: any) => {
    if (data) {
      const submitData = {
        ...data,
        valid_from: moment(data?.valid_from).format("YYYY-MM-DD"),
        valid_to: moment(data?.valid_to).format("YYYY-MM-DD"),
      };
      dispatch(createCoupons(submitData, showAll, showModal));
    }
  };


  return (
    <div
      className="as-modal create-offers-modal"
      id="add-role-modal"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex={-1}
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog-wrapper">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Create Coupon</h4>
            </div>
            <div className="modal-body">

              
              {/* for course selection */}

              <form 
               onSubmit={handleSubmit(onCouponFormSubmit)}
               > 
               <div className="form-wrapper">
                <div className="sub-section mb-3">
                  <h5 className="sub-title">Coupon Code</h5>
                  <div className="form-group">
                    <div className="d-flex">
                    <input
                          type="text"
                          className="form-group-control fw-bold"
                          {...register("coupon_code")}
                          placeholder="Coupon Code"
                        />
                        <p className="validation-text">{errors?.coupon_code?.message?.toString()}</p>
                      {/* <button className="ms-2">
                        <RefreshIcon />
                      </button>          */}
                    </div>
                  </div>
                </div>

                <div className="sub-section mb-3">
                  <h5 className="sub-title">Discount</h5>
                  <div className="d-flex align-items-center gap-4 mb-2">
                    <div className="form-radio-btn">
                    <input
                            type="radio"
                            id="radio1"
                            checked={watch("descount_type") === "percentage"}
                            onClick={() => setValue("descount_type", "percentage")}
                          />
                      <label htmlFor="radio1">Percentage</label>
                      <p className="validation-text" style={{color:"#d43d35",fontSize:"1vw"}}>{errors.descount_type?.message?.toString()}</p>
                    </div>
                    <div className="form-radio-btn">
                    <input
                            type="radio"
                            id="radio2"
                            checked={watch("descount_type") === "amount"}
                            onClick={() => setValue("descount_type", "amount")}
                          />
                      <label htmlFor="radio2">Value</label>
                    </div>
                  </div>
                  <div className="form-group mt-3">
                      {watch("descount_type") === "percentage" ?
                        <>
                          <h5 className="form-group-label">Discount percentage</h5>
                          <div className="icon-form-control">
                            {/* <div className="start-icon">
                              <a>%</a>
                            </div> */}
                            <input
                              type="text"
                              className="form-group-control fw-bold"
                              placeholder="Enter discount percentage"
                              {...register("descount_value")}
                            />
                            <p className="validation-text">{errors.descount_value?.message}</p>
                          </div>
                        </>
                        :
                        <>
                          <h5 className="form-group-label">Discount value</h5>
                          <div className="icon-form-control">
                            <input
                              type="text"
                              className="form-group-control fw-bold"
                              placeholder="Enter discount value"
                              {...register("descount_value")}
                            />
                            <p className="validation-text">{errors.descount_value?.message}</p>
                          </div>
                        </>}
                    </div>
                </div>

                <div className="sub-section mb-5">
                  <h5 className="sub-title">Validity</h5>
                  <div className="row">
                    <div className="col-5">
                      <div className="form-group">
                        <h5 className="form-group-label">Start date</h5>
                        <div className="icon-form-control">
                          <input
                              type="date"
                              className="form-group-control"
                              placeholder="Start date"
                              {...register("valid_from")}
                              min="2000-01-01"
                              max="2500-12-31"
                            />
                          <p className="validation-text">{errors.valid_from?.message}</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-1">
                      <div className="d-flex align-items-end h-100">
                        <p className="desc-text mb-2">To</p>
                      </div>
                    </div>
                    <div className="col-5">
                      <div className="form-group">
                        <h5 className="form-group-label">End date</h5>
                        <div className="icon-form-control">
                          <div className="start-icon">
                          </div>
                          <input
                              type="date"
                              className="form-group-control"
                              placeholder="End date"
                              {...register("valid_to")}
                              min="2000-01-01"
                              max="2500-12-31"
                            />
                          <p className="validation-text">{errors.valid_to?.message}</p>
                        </div>
                      </div>
                      
                    </div>
                    <div className="form-group mt-3">
                        <h5 className="form-group-label mb-2">Status</h5>
                        <div className="d-flex align-items-center gap-4 mb-2">
                          <div className="form-radio-btn">
                            <input
                              type="radio"
                              checked={watch("status") === "active"}
                              onClick={() => setValue("status", "active")}
                            />
                            <label htmlFor="radio1">Active</label>
                          </div>
                          <div className="form-radio-btn">
                            <input
                              type="radio"
                              checked={watch("status") === "inactive"}
                              onClick={() => setValue("status", "inactive")}
                            />
                            <label htmlFor="radio2">Inactive</label>
                          </div>
                        </div>
                        <p className="validation-text">{errors.status?.message}</p>
                      </div>
                  </div>
                </div>

                <div className="d-flex btn-wrapper justify-content-between mt-3">
                  <button
                    type="button"
                    className="btn btn-sm btn-outline-danger"
                    onClick={() => showModal(false)}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="btn btn-sm btn-primary close-modal-btn"
                  >
                    Create Coupon
                  </button>
                </div>
              </div>
               </form>


              {/* { next == 2 ? 
               <form 
               onSubmit={handleSubmit(onOfferFormSubmit)}
               > 
               <div className="form-wrapper">
                <div className="sub-section mb-3">
                  <h5 className="sub-title">Coupon Code</h5>
                  <div className="form-group">
                    <div className="d-flex">
                      <input
                        type="text"
                        className="form-group-control fw-bold"
                        {...register("title")}
                        name="code"
                      />
                        <p className="validation-text">{errors.title?.message}</p>
                      <button className="ms-2">
                        <RefreshIcon />
                      </button>         
                    </div>
                  </div>
                </div>

                <div className="sub-section mb-3">
                  <h5 className="sub-title">Discount</h5>
                  <div className="d-flex align-items-center gap-4 mb-2">
                    <div className="form-radio-btn">
                      <input
                        type="radio"
                        {...register("descount_type")}
                        name="descount_type"
                        id="radio1"
                        value="percentage"
                        className=""
                        onClick={()=> setType("percentage")}
                      />
                      <label htmlFor="radio1">Percentage</label>
                      <p className="validation-text" style={{color:"red"}}>{errors.descount_type?.message}</p>
                    </div>
                    <div className="form-radio-btn">
                      <input
                        type="radio"
                        {...register("descount_type")}
                        name="descount_type"
                        id="radio2"
                        value="value"
                        onChange={()=> setType("value")}
                      />
                      <label htmlFor="radio2">Value</label>
                    </div>
                  </div>
                 {type == "percentage" ? <div className="form-group">
                    <h5 className="form-group-label">Enter discount percentage</h5>
                    <div className="icon-form-control">
                      <div className="start-icon">
                        <a>%</a>
                      </div>
                      <input
                        type="text"
                        className="form-group-control fw-bold"
                        placeholder="Enter discount percentage"
                        {...register("descount_value")}
                        name="descount_percentage"
                      />
                      <p className="validation-text">{errors.descount_value?.message}</p>
                    </div>
                  </div>
                  : type == "value" && <div className="form-group">
                    <h5 className="form-group-label">Enter discount value</h5>
                    <div className="icon-form-control">
                      <input
                        type="text"
                        className="form-group-control fw-bold"
                        placeholder="Enter discount value"
                        {...register("descount_value")}
                        name="descount_value"
                      />
                      <p className="validation-text">{errors.descount_value?.message}</p>
                    </div>
                  </div>}
                </div>

                <div className="sub-section mb-5">
                  <h5 className="sub-title">Validity</h5>
                  <div className="row">
                    <div className="col-5">
                      <div className="form-group">
                        <h5 className="form-group-label">Start date</h5>
                        <div className="icon-form-control">
                          <div className="start-icon">
                            <a>
                              <CalendarIcon />
                            </a>
                          </div>
                          <input
                            type="date"
                            className="form-group-control"
                            placeholder="Start date"
                            {...register("valid_from")}
                            name="valid_from"
                          />
                          <p className="validation-text">{errors.valid_from?.message}</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-1">
                      <div className="d-flex align-items-end h-100">
                        <p className="desc-text mb-2">To</p>
                      </div>
                    </div>
                    <div className="col-5">
                      <div className="form-group">
                        <h5 className="form-group-label">End date</h5>
                        <div className="icon-form-control">
                          <div className="start-icon">
                            <a>
                              <CalendarIcon />
                            </a>
                          </div>
                          <input
                            type="date"
                            className="form-group-control"
                            placeholder="End date"
                            {...register("valid_to")}
                            name="valid_to"
                          />
                          <p className="validation-text">{errors.valid_to?.message}</p>
                        </div>
                      </div>
                      
                    </div>
                    <div>
                          Status
                          <input
                        type="radio"
                        {...register("status")}
                        name="status"
                        id="radio1"
                        className=""
                      />
                     <p className="validation-text" style={{color:"red",fontSize:"1vw"}}>{errors.status?.message}</p>
                        </div>
                  </div>
                </div>

                <div className="d-flex btn-wrapper justify-content-between mt-3">
                  <button
                    type="button"
                    className="btn btn-sm btn-outline-danger"
                    onClick={() => showModal(false)}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="btn btn-sm btn-primary close-modal-btn"
                  >
                    Create Coupon
                  </button>
                </div>
              </div>
               </form>
              :
              <div className="form-wrapper">
                <div className="row mb-2">
                  <div className="col-9">
                    <div className="form-group">
                      <div className="search-box">
                        <button className="search-btn">
                          <SearchIcon />
                        </button>
                        <input type="text" className="form-group-control" />
                      </div>
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="form-group">
                      <select
                        id="role_option"
                        className="form-select form-group-dropdown"
                      >
                        <option value="" disabled>
                          All
                        </option>
                        <option className="form-group-option active selected">
                          All
                        </option>
                      </select>
                    </div>
                  </div>
                </div>

                <div className="select-all">
                  <div className="form-check">
                    <label className="form-check-label" htmlFor={`select-all`}>
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id={`select-all`}
                      />
                      <span className="form-check-label-text">
                        Select all Courses
                      </span>
                    </label>
                  </div>
                </div>
                {!program && <p style={{color:"red",fontSize:"1vw"}}>Select course</p>}
                <div className="modal-scroll-area">
                  <ul className="courses-list">
                    {courseList.filter((data:any)=> data.name).map((course: any, index: any)=>{
                      return <>
                      <li key={index} className="course-item">
                      <div className="form-check">
                        <label
                          className="form-check-label"
                          htmlFor={`course-1`}
                        >
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id={`course-1`}
                            onChange={(e)=>{
                              setTitle(()=> [...title,course.name])
                              setProgram(e.target.checked)
                            }
                          }
                          />
                          <span className="form-check-label-text">
                            {course.name}
                          </span>
                        </label>
                      </div>
                    </li>
                      </>
                    })}
                  </ul>
                </div>

                <div className="d-flex btn-wrapper justify-content-between mt-3">
                  <button
                    type="button"
                    className="btn btn-sm btn-outline-danger"
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="btn btn-sm btn-outline-primary close-modal-btn"
                    disabled={!program && true}
                    onClick={()=>setNext((currentPage)=> currentPage + 1)}
                  >
                    Next
                  </button>
                </div>
                
              </div>
             } */}

              
              
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateCouponsModal;
