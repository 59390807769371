// import React, { useEffect, useRef } from "react";
// import "./chat-bubble.scss";

// interface IDuserChat{
//   userChat:any;
// }

// const UserChatBubble: React. FC<IDuserChat> = (props) => {
//   const {userChat ={} }= props;

//    const reversedUserChat = [...userChat].reverse()

//   return (
//     <>
//   {reversedUserChat.map((chat: any) => (
    
//     <div className="user-chat-bubble-wrap "  key={chat?.id}>
//       {chat?.message_by === "admin" ? (
//       <div className="chat-body ">
//         <div className="chat-bubble ">
//           <p className="text">
//           {chat?.message}
//           </p>
//         </div>
//         <p className="time-stamp">{ chat?.created_at}</p>
//       </div>
//       ):null}
//     </div>

//     ))}
//     </>
//   );
// };

// export default UserChatBubble;

import React, { useEffect, useRef } from "react";
import "./chat-bubble.scss";

interface IDuserChat {
  userChat: any;
}

const UserChatBubble: React.FC<IDuserChat> = (props) => {
  const { userChat = {} } = props;
  const reversedUserChat = [...userChat].reverse();
  const chatBottomRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // Scroll to the bottom when component mounts or userChat changes
    if (chatBottomRef.current) {
      chatBottomRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [userChat]);

  return (
    <>
      {reversedUserChat.map((chat: any, index: number) => (
        <div className="user-chat-bubble-wrap" key={chat?.id}>
          {chat?.message_by === "admin" ? (
            <div className="chat-body">
              <div className="chat-bubble">
                <p className="text">{chat?.message}</p>
              </div>
              <p className="time-stamp">{chat?.created_at}</p>
            </div>
          ) : null}
          {index === reversedUserChat.length - 1 && (
            <div ref={chatBottomRef}></div>
          )}
        </div>
      ))}
    </>
  );
};

export default UserChatBubble;
