import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import "./modal-styles.scss";
import * as yup from "yup";

import { createRole, getPermissionList, getRoleList, setNewRole, setUserRoleInfo, updateUserRole, userRoleUpdate } from "../../../../../redux/pages/userSlice";
import { useAppSelector } from "../../../../../redux/hooks";
import { showNotification } from "../../../../../common/components/Toaster/Toast";

interface roleProps{
  selectedUser: any;
  setShowAddRole: (value:boolean) => void;
}

const EditRoleModal: React.FC<roleProps> = (props) => {
  const {
    selectedUser = "",
    setShowAddRole = () => { }
  } = props;

  const manageRoleSchema = yup.object().shape({
    name: yup.string().trim().required("Name is required"),
    description: yup.string().trim().required("Description is required"),
  });

  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(manageRoleSchema),
  });

  const roleFormRef = useRef<any>();

  const [roleFlag, setRoleFlag] = useState<string>('select');
  const [userRoleData, setUserRoleData] = useState<any>([]);
  const [permissionsList, setPermissionsList] = useState<any>([]);
  const [selectedRoleId, setSelectedRoleId] = useState<any>("");
  const [roleValidation, setRoleValidation] = useState<string>("");

  //redux
  const dispatch = useDispatch<any>();
  const permissionsInfo = useAppSelector((state) => state.user?.permissionList);
  const rolesInfo = useAppSelector((state) => state.user?.roleList);
  const newRole = useAppSelector((state) => state.user.newRole);

  useEffect(() => {
    dispatch(getPermissionList());
    dispatch(getRoleList());
  }, []);

  useEffect(() => {
    setUserRoleData(rolesInfo);
  }, [rolesInfo]);

  useEffect(() => {
    if(newRole) {
      setSelectedRoleId(newRole?.id);
      dispatch(updateUserRole(newRole?.id, setShowAddRole));
      dispatch(setNewRole(null));
    }
  }, [newRole]);

  useEffect(() => {
    if (permissionsInfo && permissionsInfo?.length) {
      const tempPermissions = permissionsInfo.map((item: any) => {
        const updatedData = item?.data?.map((role: any) => ({ ...role, has_role: false }));
        return { ...item, data: updatedData };
      });
      setPermissionsList([...tempPermissions]);
    }
  }, [permissionsInfo]);

  useEffect(() => {
    if (roleFlag === 'add') {
      dispatch(getPermissionList());
    }
  }, [roleFlag]);

  const handleCheckboxChange = (itemId: any) => {
    const newData = permissionsList?.map((item: any) => ({
      ...item,
      data: item?.data?.map((permission: any) => permission.id === itemId ? { ...permission, has_role: !permission.has_role } : permission)
    }));
    setPermissionsList(newData);
  };

  const handleNextAction = () => {
    document.getElementById("pills-permissions-tab")?.click();
  };

  const handleRoleSave = () => {
    if (watch("name") === "" || watch("description") === "") {
      document.getElementById("pills-basic-profile-tab")?.click();
      roleFormRef?.current?.requestSubmit();
    } else if (!(permissionsList?.some((item: any) => item.data.some((role: any) => role.has_role === true)))) {
      document.getElementById("pills-permissions-tab")?.click();
      showNotification({
        message: "Atleast one permission should be selected",
        theme: "coloured",
        type: "error"
      });
    } else {
      const selectedIds = permissionsList?.flatMap((item: any) =>
        item.data.filter((permission: any) => permission.has_role).map((item: any) => item.id)
      );
      const submitData = {
        name: watch("name"),
        description: watch("description"),
        permission: [...selectedIds]
      };
      dispatch(createRole(submitData, setShowAddRole));
    }
  };

  const onSelectRole = (roleId: any) => {
    if (roleId) {
      setRoleFlag('select');
      setSelectedRoleId(roleId);
      setRoleValidation("");
    }
  };

  const handleSaveAction = () => {
    if(roleFlag === 'add') {
      roleFormRef?.current?.requestSubmit();
    } else {
      if(selectedRoleId && selectedUser && selectedUser?.id){
        const body = {
          user_id: selectedUser?.id,
          role_id: parseInt(selectedRoleId)
        };
        dispatch(updateUserRole(body, setShowAddRole));
      } else {
        setRoleValidation("This field is required");
      }
    }
  };

  return (
    <div
      className="as-modal add-role-modal"
      id="add-role-modal"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex={-1}
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div
        className="modal-dialog-wrapper"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Add Role</h4>
            </div>
            <div className="modal-body">
              <div className="tab-section">
                <ul
                  className="nav nav-pills mb-3"
                  id="pills-tab"
                  role="tablist"
                >
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link active"
                      id="pills-basic-profile-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-basic-profile"
                      type="button"
                      role="tab"
                      aria-controls="pills-home"
                      aria-selected="true"
                    >
                      Roles
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      disabled={roleFlag === 'select'}
                      className="nav-link"
                      id="pills-permissions-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-permissions"
                      type="button"
                      role="tab"
                      aria-controls="pills-permissions"
                      aria-selected="false"
                    >
                      Permissions
                    </button>
                  </li>
                </ul>
                <div className="tab-content" id="pills-tabContent">
                  <div
                    className="tab-pane fade show active"
                    id="pills-basic-profile"
                    role="tabpanel"
                    aria-labelledby="pills-basic-profile-tab"
                    tabIndex={0}
                  >
                    <div className="form-wrapper">
                      <form onSubmit={handleSubmit(handleNextAction)} ref={roleFormRef}>
                        <div className="tab-panel-scroll-area">
                          <div className={`form-group ${roleFlag === 'add' ? "disabled" : ""}`}>
                            <h5 className="form-group-label">Select role</h5>
                            <select id="role_option"
                              className="form-select form-group-dropdown"
                              disabled={!userRoleData?.length || roleFlag === 'add'}
                              onChange={(e) => onSelectRole(e?.target?.value)}
                              value={selectedRoleId}
                            >
                              <option value="" disabled>Select a role</option>
                              {userRoleData?.map((role: any, key: any) => (
                                <option key={key} value={role.id} className="form-group-option active selected" >
                                  {role?.name}
                                </option>
                              ))}
                            </select>
                            {roleValidation ? <p className="validation-text">{roleValidation}</p> : null}
                          </div>
                          <div className="d-flex align-items-center gap-4 mt-3">
                          <div className={`form-radio-btn ${!userRoleData?.length ? "disabled" : ""}`}>
                              <input
                                type="radio"
                                name="radioDefault"
                                id="radio1"
                                value='select'
                                className={!userRoleData?.length ? "disabled" : ""}
                                disabled={!userRoleData?.length}
                                checked={roleFlag === 'select'}
                                onChange={() => {
                                  setRoleFlag('select');
                                  setSelectedRoleId("");
                                  reset();
                                  dispatch(setUserRoleInfo(""));
                                }}
                              />
                              <label htmlFor="radio1">Select Role</label>
                            </div>
                            <div className="form-radio-btn">
                              <input
                                type="radio"
                                name="radioDefault"
                                id="radio2"
                                value="add"
                                checked={!userRoleData?.length || roleFlag === 'add'}
                                onChange={() => {
                                  setRoleFlag('add');
                                  setSelectedRoleId("");
                                  reset();
                                  dispatch(setUserRoleInfo(""));
                                  setRoleValidation("");
                                }}
                              />
                              <label htmlFor="radio2">Add new Role</label>
                            </div>
                          </div>

                          <div className={`form-group ${roleFlag === 'select' ? "disabled" : ""}`}>
                            <h5 className="form-group-label">Role name</h5>
                            <input
                              type="text"
                              className="form-group-control"
                              {...register("name")}
                              name="name"
                              placeholder="Enter role name"

                            />
                            <p className="validation-text">{errors.name?.message}</p>
                          </div>
                          <div className={`form-group ${roleFlag === 'select' ? "disabled" : ""}`}>
                            <h5 className="form-group-label">
                              Role Description
                            </h5>
                            <textarea
                              {...register("description")}
                              rows={4}
                              name="description"
                              className="form-group-textarea"
                              placeholder="Enter role description"
                            ></textarea>
                            <p className="validation-text">{errors.description?.message}</p>
                          </div>
                        </div>
                        <div className="d-flex btn-wrapper justify-content-between mt-3">
                          <button
                            type="button"
                            className="btn btn-sm btn-outline-danger"
                            onClick={() => setShowAddRole(false)}
                          >
                            Cancel
                          </button>
                          <button
                            type="button"
                            className="btn btn-sm btn-outline-primary close-modal-btn"
                            onClick={() => handleSaveAction()}
                          >
                            {roleFlag === 'add' ? 'Next' : 'Save'}
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>

                  <div
                    className="tab-pane fade"
                    id="pills-permissions"
                    role="tabpanel"
                    aria-labelledby="pills-permissions"
                    tabIndex={0}
                  >
                    <div className="form-wrapper">
                      <div className="tab-panel-scroll-area">
                        {permissionsList?.map((item: any, index: any) => (
                          <div key={index} className="permissions-section">
                            <h6 className="section-title">{item.title}</h6>
                            <ul className="permissions-list">
                              {item?.data.map((permission: any, idx: any) => (
                                <li key={idx} className="permission-item">
                                  <div className="form-check">
                                    <label
                                      className="form-check-label"
                                      htmlFor={`permission_${permission.id}`}
                                    >
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id={`permission_${permission.id}`}
                                        checked={permission.has_role}
                                        onChange={() => handleCheckboxChange(permission?.id)}
                                      />
                                      <span className="form-check-label-text">
                                        {permission?.name}
                                      </span>
                                    </label>
                                  </div>
                                </li>
                              ))}
                            </ul>
                          </div>
                        ))}

                      </div>
                      <div className="d-flex btn-wrapper justify-content-between mt-3">
                        <button
                          onClick={() => setShowAddRole(false)}
                          type="button"
                          className="btn btn-sm btn-outline-danger"
                        >
                          Cancel
                        </button>
                        <button
                          type="button"
                          className="btn btn-sm btn-primary close-modal-btn"
                          onClick={() => handleRoleSave()}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditRoleModal;
