import { useDispatch } from "react-redux";

import { useCallback, useEffect, useState } from "react";
import { useAppSelector } from "../../../../../../../../../redux/hooks";
import {
  deleteChapter,
  deleteTest,
  getChaptersList,
  getTestsList,
  setSelectedItemInfo,
} from "../../../../../../../../../redux/pages/courseSlice";

import { ReactComponent as NotesIcon } from "../../../../../../../../../assets/icons/icon-notes.svg";
import { ReactComponent as ClipboardTextIcon } from "../../../../../../../../../assets/icons/icon-clipboard-text.svg";
import { ReactComponent as DotsVerticalIcon } from "../../../../../../../../../assets/icons/icon-dots-vertical.svg";
import { ReactComponent as TrashIcon } from "../../../../../../../../../assets/icons/icon-trash.svg";
import { ReactComponent as EditIcon } from "../../../../../../../../../assets/icons/icon-edit.svg";
import FilsIllustraion from "../../../../../../../../../assets/images/files-illustraion.png";
import DeleteModal from "../../../../../../../../../common/components/Modal/DeleteModal/DeleteModal";

interface CTDetails {
  selectedItem?: any;
}

const ChapterList: React.FC<CTDetails> = (props) => {
  const { selectedItem = "chapter" } = props;

  const [chapterId, setChapterId] = useState<any>(null);
  const [testId, setTestId] = useState<any>(null);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

  //redux
  const dispatch = useDispatch<any>();
  const newSubject = useAppSelector((state) => state.course.newSubject);
  const chaptersList = useAppSelector((state) => state.course.chaptersList);
  const subjectTestsList = useAppSelector(
    (state) => state.course.subjectTestsList
  );
  const selectedRow = useAppSelector((state) => state.course.selectedItemInfo);

  useEffect(() => {
    if (newSubject && newSubject?.id) {
      if (selectedItem === "chapter") {
        dispatch(getChaptersList(newSubject?.id));
      } else {
        const body = { subject_id: newSubject?.id };
        dispatch(getTestsList(body));
      }
    }

    return () => {
      dispatch(setSelectedItemInfo(null));
    };
  }, [selectedItem]);

  const onSelectData = useCallback((data: any) => {
    if (data) {
      dispatch(setSelectedItemInfo(data));
    }
  }, []);

  const onDeleteBtnClicked = (item: any) => {
    if (item) {
      if (selectedItem === "chapter") {
        setChapterId(item);
      } else {
        setTestId(item);
      }
      setShowDeleteModal(true);
    }
  };

  const onConfirmDelete = () => {
    if (selectedItem === "chapter") {
      if (chapterId && newSubject?.id) {
        const data = { id: chapterId, subject_id: newSubject?.id };
        dispatch(
          deleteChapter(data, newSubject?.id, setShowDeleteModal, setChapterId)
        );
      }
    } else {
      if (testId && newSubject?.id) {
        const body = { subject_id: newSubject?.id };
        const data = { id: testId, subject_id: newSubject?.id };
        dispatch(deleteTest(data, body, setShowDeleteModal, setTestId));
      }
    }
  };

  const onCancelDeleteAction = () => {
    setChapterId(null);
  };

  const emptyTemplate = () => {
    return (
      <div className="d-flex align-items-center justify-content-center empty-msg-wrap">
        <div className="empty-msg">
          <div className="files-illustraion mb-3">
            <img
              src={FilsIllustraion}
              className="img-fluid"
              alt="empty illustraion"
            />
          </div>
          <p className="description">
            No {selectedItem === "chapter" ? "chapters" : "tests"} created
            created for {newSubject?.name}
          </p>
        </div>
      </div>
    );
  };

  return (
    <div className="col-xl-5">
      <div className="card h-100">
        <div className="card-title-wrapper">
          <h3 className="card-title">{newSubject?.name}</h3>
          <h4 className="card-subtitle mb-4">
            {selectedItem === "chapter" ? "Chapters" : "Tests"}
          </h4>
          <div className="line"></div>
        </div>
        <div className="card-inner-scroll-area">
          <div className="pe-2">
            {selectedItem === "chapter" ? (
              <>
                {chaptersList?.length ? (
                  <>
                    {chaptersList?.map((item: any, key: any) => (
                      <button
                        key={key}
                        className={`list-card justify-content-between ${
                          selectedRow?.id === item?.id ? "selected" : ""
                        }`}
                      >
                        <div className="d-flex">
                          <span className="me-2">
                            <NotesIcon />
                          </span>
                          <p className="title">{item?.name}</p>
                        </div>

                        <div className="dropdown more-dropdown">
                          <button
                            className="dropdown-toggle more-dropdown-btn dropdown-arrow-hide"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <DotsVerticalIcon />
                          </button>

                          <ul className="dropdown-menu more-dropdown-menu">
                            <li>
                              <a
                                className="dropdown-item"
                                onClick={() => onSelectData(item)}
                              >
                                <span className="me-2">
                                  <EditIcon />
                                </span>
                                Edit
                              </a>
                            </li>
                            <li>
                              <button
                                className="dropdown-item text-danger"
                                onClick={() => onDeleteBtnClicked(item?.id)}
                              >
                                <span className="me-2">
                                  <TrashIcon />
                                </span>
                                Delete
                              </button>
                            </li>
                          </ul>
                        </div>
                      </button>
                    ))}
                  </>
                ) : (
                  emptyTemplate()
                )}
              </>
            ) : (
              <>
                {subjectTestsList?.length ? (
                  <>
                    {subjectTestsList?.map((item: any, key: any) => (
                      <button
                        key={key}
                        className={`list-card justify-content-between ${
                          selectedRow?.id === item?.id ? "selected" : ""
                        }`}
                      >
                        <div className="d-flex">
                          <span className="me-2">
                            <ClipboardTextIcon />
                          </span>
                          <p className="title">{item?.name}</p>
                        </div>

                        <div className="dropdown more-dropdown">
                          <button
                            className="dropdown-toggle more-dropdown-btn"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <DotsVerticalIcon />
                          </button>

                          <ul className="dropdown-menu more-dropdown-menu">
                            <li>
                              <a
                                className="dropdown-item"
                                onClick={() => onSelectData(item)}
                              >
                                <span className="me-2">
                                  <EditIcon />
                                </span>
                                Edit
                              </a>
                            </li>
                            <li>
                              <button
                                className="dropdown-item text-danger"
                                onClick={() => onDeleteBtnClicked(item?.id)}
                              >
                                <span className="me-2">
                                  <TrashIcon />
                                </span>
                                Delete
                              </button>
                            </li>
                          </ul>
                        </div>
                      </button>
                    ))}
                  </>
                ) : (
                  emptyTemplate()
                )}
              </>
            )}
          </div>
        </div>
      </div>
      {showDeleteModal && (
        <DeleteModal
          showModal={setShowDeleteModal}
          onConfirm={onConfirmDelete}
          onCancel={onCancelDeleteAction}
          message={`The chapter 
               ${
                 selectedItem === "chapter"
                   ? chaptersList?.find((item: any) => item?.id === chapterId)
                       ?.name
                   : subjectTestsList?.find((item: any) => item?.id === testId)
                       ?.name
               } will be permanently deleted.`}
        />
      )}
    </div>
  );
};

export default ChapterList;
