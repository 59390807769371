import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { ReactComponent as CircleCloseIcon } from "../../../../../assets/icons/icon-close-circle.svg";
import { categorySchema } from "../../../../../validations/categoryValidation";
import { useAppSelector } from "../../../../../redux/hooks";
import { subcategorySchema } from "../../../../../validations/subCategoryValidation";

interface EditInfo {
  showModal: (value: boolean) => void;
  onEditSubProgram: (value: boolean) => void;
  subprogramInfo: any;
}

const  EditsubCategoryModal: React.FC<EditInfo> = (props) => {
  const {
    showModal = () => {},
    onEditSubProgram = () => {},
    subprogramInfo,
  } = props;

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: subprogramInfo.name,
      price: subprogramInfo.price,
      description:subprogramInfo.description
    },
    resolver: yupResolver(subcategorySchema),
  });

  const [programList,setProgramList]= useState<any>([])
  const [program_id, setProgram_id] =useState<any>([]);

  const programInfo= useAppSelector ((state) => state.programs.programList);

  const onEditFormSubmit = async (data: any) => {
    const submitData = { ...data, status: "active", id: subprogramInfo.id, program_id:subprogramInfo?.program_id};
    onEditSubProgram(submitData);
  };

  useEffect(()=>{
    if(programInfo){
      setProgramList(programInfo?.programs)
    }
      },[programInfo])

  return (
    <div
      className="as-modal edit-category-modal"
      id="staticBackdrop"
      aria-hidden="true"
    >
      <div className="modal-dialog-wrapper">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header mb-3">
              <h4 className="modal-title">Edit New sub-Category</h4>
              <button
                className="close-button close-modal-btn"
                onClick={() => showModal(false)}
              >
                <CircleCloseIcon />
              </button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleSubmit(onEditFormSubmit)}>

              <div className={`category-form-control-wrap mb-3 ${!programList?.length ? "disabled" : ""}`}>
                  <p className="form-label">Category Name</p>
                 
                  <select
                  {...register("program_id")}
                  id="program_id"
                  className={`form-select form-group-dropdown ${errors?.program_id ? "error-border" : ""}`}
                  onChange={(e) => setProgram_id(e.target.value)} >
                  <option value="" disabled> Select Category </option>
                  {programList?.map((data: any, key: number) => (
                     <option key={key} value={data.id} className={"form-group-option"} >{data.name}</option>
                  ))}
               </select>
                  <p className="validation-text">{errors?.program_id?.message?.toString()} </p>
                </div>

                <div className="category-form-control-wrap mb-3">
                  <label className="form-label">sub Category Name</label>
                  <input
                    type="text"
                    className="form-group-control"
                    placeholder="Enter category name"
                    {...register("name")}
                    name="name"
                  />
                  <p className="validation-text">
                    {errors.name?.message?.toString()}
                  </p>
                </div>
                <div className="category-form-control-wrap mb-3">
                  <label className="form-label">sub Category Price</label>
                  <input
                    type="text"
                    className="form-group-control"
                    placeholder="Enter category Price in Rupees"
                    {...register("price")}
                    name="price"
                  />
                  <p className="validation-text">
                    {errors.price?.message?.toString()}
                  </p>
                </div>
                <div className="category-form-control-wrap mb-4">
                <label className="form-label">sub Category Description</label>
                  <textarea
                     {...register("description")}
                     name="description"
                     rows={4}
                     className="form-group-textarea"
                  ></textarea>
               </div>
                <div className="d-flex justify-content-between mt-4">
                  <button
                    type="button"
                    className="btn btn-sm btn-outline-danger close-modal-btn"
                    onClick={() => showModal(false)}
                  >
                    Cancel
                  </button>
                  <button type="submit" className="btn btn-sm btn-primary">
                    Confirm
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditsubCategoryModal;
