import * as yup from "yup";

export const resetPasswordSchema = yup.object({
    current_password: yup.string()
    .required("This field is required.")
    // .min(8, "Password is too short - should be 8 chars minimum."),
    ,
  new_password: yup.string()
    .required("This field is required.")
    .min(8, "Password is too short - should be 8 chars minimum."),
    confirm_password: yup.string()
    .required("This field is required.")
    .min(8, "Password is too short - should be 8 chars minimum."),
}).required();