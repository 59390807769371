import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../redux/hooks";
import "./Category.scss";

import { ReactComponent as AddCirclePlusIcon } from "../../../assets/icons/icon-add-circle.svg";
import { ReactComponent as SortIcon } from "../../../assets/icons/icon-sort.svg";
import { ReactComponent as DotsVerticalIcon } from "../../../assets/icons/icon-dots-vertical.svg";
import { ReactComponent as ArrowLeftIcon } from "../../../assets/icons/icon-arrow-left.svg";
import { ReactComponent as SearchNormalIcon } from "../../../assets/icons/icon-search-normal.svg";
import { ReactComponent as TrashIcon } from "../../../assets/icons/icon-trash.svg";
import { ReactComponent as EditIcon } from "../../../assets/icons/icon-edit.svg";
import { ReactComponent as BookmarkIcon } from "../../../assets/icons/icon-bookmark.svg";

import DeleteModal from "../../../common/components/Modal/DeleteModal/DeleteModal";
import EditCategoryModal from "./manage/EditCategory/EditCategoryModal";
import CreateCategoryModal from "./manage/CreateCategory/CreateCategoryModal";
import {
  deleteProgram,
  getProgramsList,
  updateProgram,
} from "../../../redux/pages/categorySlice";

const ManageCategory = () => {

  const navigate = useNavigate();

  const [programData, setProgramData] = useState<any>([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [categoryId, setCategoryId] = useState(null);
  const [programDetails, setProgramDetails] = useState<any>([]);
  const [sortOrder, setSortOrder] = useState("asc");
  const [sortField, setSortField] = useState("name"); 
  const [sortedProgramList, setSortedProgramList] = useState<any>([]);

  //redux
  const dispatch = useDispatch<any>();
  const programList = useAppSelector((state) => state.programs.programList);


  useEffect(() => {
    dispatch(getProgramsList());
  }, []);

  useEffect(() => {
    if (programList && programList.programs) {
      setProgramData(programList.programs);
    }
  }, [programList]);

  const onEditCategory = (submitData: any) => {
    dispatch(updateProgram(submitData, setShowEditModal));
  };

  const handleDelete = (id: any) => {
    setShowDeleteModal(true);
    setCategoryId(id);
  };

  const handleEditClick = (data: any) => {
    setShowEditModal(true);
    setProgramDetails(data);
  };

  const onDeleteProgram = () => {
    if (categoryId) {
      const data = { id: categoryId };
      dispatch(deleteProgram(data, setShowDeleteModal, setCategoryId));
    }
  };

  const onCancelDeleteAction = () => {
    setCategoryId(null);
  };

  const handleSortChange = (field: string) => {
    if (sortField === field) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortField(field);
      setSortOrder("asc");
    }
  };

  useEffect(() => {
    const sortedList = [...programData];
    sortedList.sort((a: any, b: any) => {
      if (sortField === "name") {
        return sortOrder === "asc" ? a.name.localeCompare(b.name) : b.name.localeCompare(a.name);
      } else if (sortField === "date") {
        return sortOrder === "asc" ? new Date(a.date).getTime() - new Date(b.date).getTime() : new Date(b.date).getTime() - new Date(a.date).getTime();
      }
      return 0;
    });

    setSortedProgramList(sortedList);
  }, [programData, sortField, sortOrder]);


  return (
    <>
      <div className="manage-category-wrapper h-100">
        {/* title section */}
        <div className="d-flex justify-content-between flex-wrap title-wrapper mb-3 mx-4">
          <button className="back-button" onClick={() => navigate("/courses")}>
            <ArrowLeftIcon />
          </button>
          <div>
            <h2 className="title">Manage Category</h2>
            <p className="desc mb-2">Create new category and manage it here.</p>
          </div>
          <div
            className="d-flex al gap-3"
            onClick={() => setShowCreateModal(true)}
          >
            <a className="btn btn-primary">
              <span className="me-1">
                <AddCirclePlusIcon />
              </span>
              Create Category
            </a>
          </div>
        </div>

        <div className="card ms-2 me-4">
          <h3 className="card-title">All Category</h3>
          {/* control section */}
          <div className="control-section-wrapper ">
            <div className="d-flex justify-content-between flex-wrap gap-3  control-wrapper py-3 me-4">
              <div className="search-wrapper me-3">
                <div className="search-box">
                  <a className="search-icon-button">
                    <SearchNormalIcon />
                  </a>
                  <input
                    type="text"
                    placeholder="Search course"
                    className="search-form-control"
                  />
                </div>
              </div>
              <div className="d-flex gap-3">
                <div className="dropdown sort-dropdown">
                  <button
                    className="control-btn dropdown-toggle"
                    // type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <span className="me-2">
                      <SortIcon />
                    </span>
                    Sort
                  </button>

                  <ul className="dropdown-menu">
                  <li>
                      <a className={`dropdown-item ${
                          sortField === "name" ? "active" : ""
                        }`}
                        href="#"
                        onClick={() => handleSortChange("name")}>
                        Name
                      </a>
                    </li>
                    <li>
                      <a className={`dropdown-item ${
                          sortField === "date" ? "active" : ""
                        }`}
                        href="#"
                        onClick={() => handleSortChange("date")}>
                        Date
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          {/* table section */}
          <div className="table-wrapper">
            <table className="table table-hover">
              <thead>
                <tr>
                  <th scope="col">Category</th>
                  <th scope="col" className="text-center">
                    Price
                  </th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                {/* {programData?.map((data: any, key: any) => ( */}
                {(sortedProgramList.length > 0 ? sortedProgramList : programData)?.map((data: any, key: any) => (

                  <tr key={key}>
                    <td className="">
                      <div className="d-flex">
                        <span className="me-2">
                          <BookmarkIcon />
                        </span>
                        {data.name}
                      </div>
                    </td>
                    <td max-width="20%" className="text-center">
                      <p className="price">$ {data.price}</p>
                    </td>
                    <td width="10%" className="text-end">
                      <div className="dropdown more-dropdown">
                        <button
                          className="dropdown-toggle more-dropdown-btn dropdown-arrow-hide"
                          // type="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <DotsVerticalIcon />
                        </button>

                        <ul className="dropdown-menu more-dropdown-menu">
                          <li onClick={() => handleEditClick(data)}>
                            <a className="dropdown-item">
                              <span className="me-2">
                                <EditIcon />
                              </span>
                              Edit
                            </a>
                          </li>
                          <li>
                            <button
                              className="dropdown-item text-danger"
                              onClick={() => handleDelete(data.id)}
                            >
                              <span className="me-2">
                                <TrashIcon />
                              </span>
                              Delete
                            </button>
                          </li>
                        </ul>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {showDeleteModal && (
        <DeleteModal
          showModal={setShowDeleteModal}
          onConfirm={onDeleteProgram}
          onCancel={onCancelDeleteAction}
          message={`The category ${
            programData?.find((item: any) => item.id === categoryId)?.name
          } will be permanently deleted.`}
        />
      )}
      {showCreateModal && (
        <CreateCategoryModal showModal={setShowCreateModal} />
      )}
      {showEditModal && (
        <EditCategoryModal
          programInfo={programDetails}
          onEditCategory={onEditCategory}
          showModal={setShowEditModal}
        />
      )}
    </>
  );
};

export default ManageCategory;
