import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import user from "../../../assets/images/user.png";

import AddMemberModal from "./components/AddMemberModal/AddMemberModal";
import ManageRoleModal from "./components/ManageRoleModal/ManageRoleModal";
import AddRoleModal from "./components/AddRoleModal/AddRoleModal";
import { useAppSelector } from "../../../redux/hooks";
import { deleteUser, getRoleList, getUserList } from "../../../redux/pages/userSlice";

import "./users.scss";
import {ReactComponent as EditIcon} from "../../../assets/icons/icon-edit.svg"
import { ReactComponent as AddCirclePlusIcon } from "../../../assets/icons/icon-add-circle.svg";
import { ReactComponent as GridPlusIcon } from "../../../assets/icons/icon-grid-plus.svg";
import { ReactComponent as SortIcon } from "../../../assets/icons/icon-sort.svg";
import { ReactComponent as CategoryIcon } from "../../../assets/icons/icon-category.svg";
import { ReactComponent as SearchNormalIcon } from "../../../assets/icons/icon-search-normal.svg";
import { ReactComponent as TrashIcon } from "../../../assets/icons/icon-trash.svg";
import { ReactComponent as DotsVerticalIcon } from "../../../assets/icons/icon-dots-vertical.svg";
import { ReactComponent as UserEditIcon } from "../../../assets/icons/icon-user-edit.svg";
import { ReactComponent as UserEditIconBlack } from "../../../assets/icons/icon-user-edit-black.svg";
import { ReactComponent as UserIcon } from "../../../assets/icons/icon-user.svg";
import { ReactComponent as DocumentFilterIcon } from "../../../assets/icons/icon-document-filter.svg";
import { ReactComponent as ShieldSecurityIcon } from "../../../assets/icons/icon-shield-security.svg";
import EditMemberModal from "./components/EditMemberModal/EditMembermodal";

const Users = () => {

  const [showRoleModal, setShowRoleModal] = useState<any>(false);
  const [showCreateModal, setShowCreateModal] = useState<any>(false);
  const [showManageRole, setShowManageRole] = useState<any>(false);
  const [showEditMember,setShowEditMember] = useState<any>(false)
  const [userData, setUserData] = useState<any>([]);
  const [selectedUser, setSelectedUser] = useState<any>(null);


  //redux
  const dispatch = useDispatch<any>();
  const userList = useAppSelector((state) => state.user?.userList);

  useEffect(() => {
    dispatch(getUserList());
    dispatch(getRoleList());
  }, []);

  useEffect(() => {
    if (userList && userList.length) {
      setUserData(userList);
    }
  }, [userList]);

  const handleClick = (id: any) => {
    dispatch(deleteUser(id));
  };

  const rolesTemplate = (data: any) => {
    let roleinfo = "";
    data.forEach((item: any) => {
      roleinfo = roleinfo + ', ' + item;
    });
    return <span className="role ms-2">({(roleinfo)?.slice(0, -1)})</span>;
  };

  const onEditUserClick = (data: any) => {
    if (data) {
      setSelectedUser(data);
      setShowEditMember(true);
      document.getElementById("pills-add-new-role-tab")?.click();
    }
  };

  const onUpdateRoleCLick = (data: any) => {
    if (data) {
      setSelectedUser(data);
      setShowRoleModal(true);
    }
  };

  return (
    <>
      <div className="users-wrapper">
        {/* title section */}
        <div className="d-flex justify-content-between flex-wrap title-wrapper mb-3 mx-4">
          <div>
            <h2 className="title">Manage Users</h2>
            <p className="desc mb-2">
              Here you can add new users, manage them etc.
            </p>
          </div>
        </div>
        <div className="card-section mx-4">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-title-wrapper">
                  <div>
                    <h3 className="card-title">Members</h3>
                    <h5 className="card-subtitle">
                      Manage users
                    </h5>
                  </div>
                  <div className="d-flex al gap-3">
                    <button onClick={() => setShowManageRole(true)} className="btn btn-sm btn-outline-primary">
                      <span className="me-1">
                        <UserEditIcon />
                      </span>
                      Manage Roles
                    </button>
                    <button onClick={() => setShowCreateModal(true)} className="btn btn-sm btn-primary">
                      <span className="me-1">
                        <AddCirclePlusIcon />
                      </span>
                      Add Member
                    </button>
                  </div>
                </div>
                <div className="control-section-wrapper">
                  <div className="d-flex justify-content-between flex-wrap gap-3  control-wrapper pt-2 pb-3">
                    <div className="search-wrapper me-3">
                      <div className="search-box">
                        <a className="search-icon-button">
                          <SearchNormalIcon />
                        </a>
                        <input
                          type="text"
                          placeholder="Search user"
                          className="search-form-control"
                        />
                      </div>
                    </div>

                    <div className="d-flex gap-3">
                      <div className="dropdown filter-dropdown">
                        <button
                          className="control-btn dropdown-toggle"
                          // type="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <span className="me-1">
                            <DocumentFilterIcon />
                          </span>
                          Filter
                        </button>

                        <form className="dropdown-menu">
                          <p className="text">Show</p>
                          <div className="form-check">
                            <label className="form-check-label" htmlFor="flexCheckChecked1" >
                              <input
                                className="form-check-input"
                                type="checkbox"
                                value=""
                                id="flexCheckChecked1"
                              />
                              <span className="form-check-label-text">
                                Everyone
                              </span>
                            </label>
                          </div>
                          <div className="form-check">
                            <label className="form-check-label" htmlFor="flexCheckChecked2" >
                              <input
                                className="form-check-input"
                                type="checkbox"
                                value=""
                                id="flexCheckChecked2"
                              />
                              <span className="form-check-label-text">
                                Admin
                              </span>
                            </label>
                          </div>
                          <div className="form-check">
                            <label
                              className="form-check-label"
                              htmlFor="flexCheckChecked3"
                            >
                              <input
                                className="form-check-input"
                                type="checkbox"
                                value=""
                                id="flexCheckChecked3"
                              />
                              <span className="form-check-label-text">
                                Financial Managers
                              </span>
                            </label>
                          </div>
                          <div className="form-check">
                            <label
                              className="form-check-label"
                              htmlFor="flexCheckChecked4"
                            >
                              <input
                                className="form-check-input"
                                type="checkbox"
                                value=""
                                id="flexCheckChecked4"
                              />
                              <span className="form-check-label-text">
                                Course Managers
                              </span>
                            </label>
                          </div>
                        </form>
                      </div>
                      <div className="dropdown sort-dropdown">
                        <button
                          className="control-btn dropdown-toggle"
                          // type="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <span className="me-1">
                            <SortIcon />
                          </span>
                          Sort
                        </button>

                        <ul className="dropdown-menu">
                          <li>
                            <a className="dropdown-item">
                              Name
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item">
                              Created
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item">
                              Last modified
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-inner-scroll-area">
                  <ul className="users-list">
                    {userData?.map((item: any, index: any) => (
                      <li key={index}>
                        <div className="user-card mb-2 ">
                          <div className="d-flex  align-items-center">
                            <div className="thumbnail-img">
                              <img
                                src={item?.profile_image ? `${item?.profile_image}` : user}
                                alt="video thumbnail"
                              />
                            </div>
                            <p className="title">{item.name}</p>
                            {item.roles && item.roles?.length ? <>
                              {rolesTemplate(item?.roles)}
                            </> : null}
                          </div>

                          <div className="d-flex align-items-center gap-3">
                            <button onClick={() => onUpdateRoleCLick(item)} className="btn btn-sm btn-outline-primary">
                              <span className="me-1">
                                <UserIcon />
                              </span>
                              Update role
                            </button>
                            <div className="dropdown more-dropdown">
                              <button
                                className="dropdown-toggle more-dropdown-btn"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <DotsVerticalIcon />
                              </button>

                              <ul className="dropdown-menu more-dropdown-menu">
                              <li>
                                  <button className="dropdown-item" onClick={() => onEditUserClick(item)}>
                                    <span className="me-2">
                                      <EditIcon />
                                    </span>
                                    Edit
                                  </button>
                                </li>
                                <li>
                                  <button onClick={() => handleClick(item.id)} className="dropdown-item text-danger">
                                    <span className="me-2">
                                      <TrashIcon />
                                    </span>
                                    Delete
                                  </button>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
            {/* <div className="col-12">
              <div className="card">
                <div className="card-title-wrapper">
                  <div>
                    <h3 className="card-title">Members</h3>
                    <h5 className="card-subtitle">Manage users</h5>
                  </div>
                </div>
                <div className="control-section-wrapper">
                  <div className="d-flex justify-content-between flex-wrap gap-3  control-wrapper pt-2 pb-3">
                    <div className="search-wrapper me-3">
                      <div className="search-box">
                        <a className="search-icon-button">
                          <SearchNormalIcon />
                        </a>
                        <input
                          type="text"
                          placeholder="Search course"
                          className="search-form-control"
                        />
                      </div>
                    </div>
                    
                    <div className="d-flex gap-3">
                      <div className="dropdown filter-dropdown">
                        <button
                          className="control-btn dropdown-toggle"
                          // type="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <span className="me-1">
                            <DocumentFilterIcon />
                          </span>
                          Filter
                        </button>

                        <form className="dropdown-menu">
                          <p className="text">Show</p>
                          <div className="form-check">
                            <label
                              className="form-check-label"
                              htmlFor="flexCheckChecked1"
                            >
                              <input
                                className="form-check-input"
                                type="checkbox"
                                value=""
                                id="flexCheckChecked1"
                              />
                              <span className="form-check-label-text">
                                Everyone
                              </span>
                            </label>
                          </div>
                          <div className="form-check">
                            <label
                              className="form-check-label"
                              htmlFor="flexCheckChecked2"
                            >
                              <input
                                className="form-check-input"
                                type="checkbox"
                                value=""
                                id="flexCheckChecked2"
                              />
                              <span className="form-check-label-text">
                                Admin
                              </span>
                            </label>
                          </div>
                          <div className="form-check">
                            <label
                              className="form-check-label"
                              htmlFor="flexCheckChecked3"
                            >
                              <input
                                className="form-check-input"
                                type="checkbox"
                                value=""
                                id="flexCheckChecked3"
                              />
                              <span className="form-check-label-text">
                                Financial Managers
                              </span>
                            </label>
                          </div>
                          <div className="form-check">
                            <label
                              className="form-check-label"
                              htmlFor="flexCheckChecked4"
                            >
                              <input
                                className="form-check-input"
                                type="checkbox"
                                value=""
                                id="flexCheckChecked4"
                              />
                              <span className="form-check-label-text">
                                Course Managers
                              </span>
                            </label>
                          </div>
                        </form>
                      </div>
                      <div className="dropdown sort-dropdown">
                        <button
                          className="control-btn dropdown-toggle"
                          // type="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <span className="me-1">
                            <SortIcon />
                          </span>
                          Sort
                        </button>

                        <ul className="dropdown-menu">
                          <li>
                            <a className="dropdown-item">
                              Name
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item">
                              Created
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item">
                              Last modified
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-inner-scroll-area">
                  <ul className="users-list">
                    {userRole?.map((eachUser: any, key:any) => {
                      <li key={key}>
                        <div className="user-card mb-2 ">
                          <div className="d-flex  align-items-center">
                            <div className="thumbnail-img">
                              <img
                                src={eachUser.profilePic}
                                alt="video thumbnail"
                              />
                            </div>
                            <p className="title">{eachUser.name}</p>
                            <span className="role ms-2">({eachUser.role})</span>
                          </div>

                          <div className="dropdown more-dropdown">
                            <button
                              className="dropdown-toggle more-dropdown-btn"
                              // type="button"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <DotsVerticalIcon />
                            </button>

                            <ul className="dropdown-menu more-dropdown-menu">
                              <li>
                                <button className="dropdown-item ">
                                  <span className="me-2">
                                    <UserEditIconBlack />
                                  </span>
                                  Edit Profile
                                </button>
                              </li>
                              <li>
                                <button className="dropdown-item">
                                  <span className="me-2">
                                    <ShieldSecurityIcon />
                                  </span>
                                  Manage Permission
                                </button>
                              </li>
                              <li>
                                <button className="dropdown-item text-danger">
                                  <span className="me-2">
                                    <TrashIcon />
                                  </span>
                                  Delete
                                </button>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </li>
                    })}
                  </ul>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
      {/* modal sections */}
      {showCreateModal && <AddMemberModal setShowMemberModal={setShowCreateModal} />}
      {showRoleModal && <AddRoleModal setShowAddRole={setShowRoleModal} selectedUser={selectedUser}/>}
      {showManageRole && <ManageRoleModal setShowManageRole={setShowManageRole} />}
      {showEditMember && <EditMemberModal setShowEditMember={setShowEditMember} userData={selectedUser} setUserData={setSelectedUser}/>}
    </>
  );
};

export default Users;
