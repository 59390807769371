import * as yup from "yup";

export const createMemberSchema = yup.object().shape({
   role: yup.string().trim().required("Role is required"),
   name: yup.string().trim().required("Name is required"),
   email: yup.string().trim().email().required("Email is required"),
   profile_image: yup.mixed(),
   phone: yup.string()
     .matches(/^\d{10}$/, 'Phone number must be 10 digits')
     .required('Phone number is required'),
   dob: yup.string().trim().required("Date of birth is required"),
   password: yup
     .string()
     .trim()
     .required('Password is required')
     .min(8, 'Password must be at least 8 characters long'),
   confirm_password: yup
     .string()
     .trim()
     .oneOf([yup.ref('password')], 'Passwords must match')
     .required('Confirm Password is required'),
 });

export const editMemberSchema = yup.object().shape({
   role: yup.string().trim().required("Role is required"),
   name: yup.string().trim().required("Name is required"),
   email: yup.string().trim().email().required("Email is required"),
   profile_image: yup.mixed(),
   phone: yup.string()
      .matches(/^\d{10}$/, 'Phone number must be 10 digits')
      .required('Phone number is required'),
   dob: yup.string().trim().required("Date of birth is required"),
   password: yup
      .string()
      .trim(),
   confirm_password: yup
      .string()
      .trim()
      .oneOf([yup.ref('password')], 'Passwords must match'),
});