import { forwardRef, memo, useEffect, useImperativeHandle, useRef } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { createTestSchema } from "../../../../../../../../../validations/courseValidations";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../../../../../../../redux/hooks";
import { createTest, updateTest } from "../../../../../../../../../redux/pages/courseSlice";

interface testDetails { };

const Tests = forwardRef<any, testDetails>((props, ref) => {
   const { } = props;

   const {
      register,
      handleSubmit,
      formState: { errors },
      setValue,
      reset,
      clearErrors,
   } = useForm({
      resolver: yupResolver(createTestSchema),
      defaultValues: {
         name: "",
         description: "",
      }
   });

   const testFormRef = useRef<any>();

   //redux
   const dispatch = useDispatch<any>();
   const newSubject = useAppSelector((state) => state.course.newSubject);
   const selectedRowItem = useAppSelector((state) => state.course.selectedItemInfo);

   useImperativeHandle(ref, () => ({
      onSaveTrigger: () => {
         testFormRef?.current?.requestSubmit();
      }
   }));

   useEffect(() => {
      if (selectedRowItem) {
         setValue("name", selectedRowItem?.name);
         setValue("description", selectedRowItem?.description);
         clearErrors(["name", "description"]);
      } else {
         setValue("name", "");
         setValue("description", "");
      }
   }, [selectedRowItem]);

   const onSubmit = (data: any) => {
      if (data) {
         if (selectedRowItem) {
            const body = { subject_id: newSubject?.id };
            const submitData = { ...data, subject_id: newSubject?.id, id: selectedRowItem?.id };
            dispatch(updateTest(submitData, reset, body));
         } else {
            if (newSubject && newSubject?.id) {
               const body = { subject_id: newSubject?.id };
               const submitData = { ...data, subject_id: newSubject?.id };
               dispatch(createTest(submitData, reset, body));
            }
         }
      }
   };

   return (
      <div className="form-wrapper">
         <form ref={testFormRef} onSubmit={handleSubmit(onSubmit)}>
            <div className="form-group mb-4">
               <h5 className="form-group-label">Test name</h5>
               <h6 className="form-group-hint">
                  Enter the name of the test
               </h6>
               <input
                  {...register("name")}
                  type="text"
                  className={`form-group-control ${errors?.name ? "error-border" : ""}`}
               />
               <p className="validation-text">{errors?.name?.message}</p>
            </div>

            <div className="form-group mb-4">
               <h5 className="form-group-label">Description</h5>
               <h6 className="form-group-hint">
                  Write description about the test
               </h6>

               <textarea
                  {...register("description")}
                  id="test_desc"
                  rows={5}
                  className={`form-group-textarea ${errors?.description ? "error-border" : ""}`}
                  placeholder="Enter the description"
               ></textarea>
               <p className="validation-text">{errors?.description?.message}</p>
            </div>
         </form>
      </div>
   );
});

export default memo(Tests);