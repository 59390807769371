import * as yup from "yup";

export const offerSchema = yup.object().shape({
   title: yup.string().required("Code is required"),
   descount_type:yup.string().required("Discount type is required"),
   descount_value: yup.number()
   .typeError('Must be a number')
      .when("descount_type",(descount_type:any,schema:any)=>{
         if(descount_type == "value") return schema.required()
      })
      .min(0, "Minimum value should be 0")
      .positive("Minimum value should be 0")
      .integer(),
      valid_from: yup.date().typeError("Start date is required").required("Start date is required").min("2000-01-01", "Start date must be 01-01-2000 or later"),
      valid_to: yup.date().typeError("End date is required").min(yup.ref("valid_from"), "End date must greater than start date").required("End date is required"),
      status:yup.string().required("Status is required")
});


