import { forwardRef, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";

import { ReactComponent as TrashIcon } from "../../../../../../../../assets/icons/icon-trash.svg";
import { ReactComponent as PlayCircleIcon } from "../../../../../../../../assets/icons/icon-play-circle.svg";
import { showNotification } from "../../../../../../../../common/components/Toaster/Toast";
import { createLectureSchema } from "../../../../../../../../validations/courseValidations";
import { useAppSelector } from "../../../../../../../../redux/hooks";
import {
  createTutorial,
  getTutorialDetails,
  setSelectedContentInfo,
  updateTutorial,
} from "../../../../../../../../redux/pages/courseSlice";
registerPlugin(FilePondPluginFileValidateType);

interface lectureDetails {
  selectedChapter: any;
}

const Lecture = forwardRef<any, lectureDetails>((props, ref) => {
  const { selectedChapter = "" } = props;

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
    reset,
  } = useForm({
    resolver: yupResolver(createLectureSchema),
    defaultValues: {
      name: "",
      description: "",
      video_link: "",
      notes: "",
    },
  });

  const lectureFormRef = useRef<any>();
  const fileUploadRef = useRef<any>();

  const [file, setFile] = useState<any>("");
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [previousVideo, setPreviousVideo] = useState<any>(null);

  const dispatch = useDispatch<any>();
  const accessToken: any = useAppSelector((state) => state.login.accessToken);
  const selectedContentInfo = useAppSelector(
    (state) => state.course.selectedContentInfo
  );
  const lectureDetails = useAppSelector(
    (state) => state.course.tutorialDetails
  );

  useEffect(() => {
    if (selectedContentInfo && selectedChapter) {
      const body = {
        id: selectedContentInfo,
        chapter_id: selectedChapter,
      };
      setIsEditMode(true);
      dispatch(getTutorialDetails(body));
    } else {
      setIsEditMode(false);
    }
  }, [selectedContentInfo]);

  useEffect(() => {
    if (isEditMode) {
      reset();
      setFile("");
    }
  }, [selectedChapter]);

  useEffect(() => {
    if (lectureDetails) {
      setValue("name", lectureDetails?.name);
      setValue("description", lectureDetails?.description);
      setValue("video_link", lectureDetails?.video_link);
      setValue("notes", lectureDetails?.notes);
      setPreviousVideo(lectureDetails?.video_link);
      clearErrors(["description", "name", "video_link"]);
    }
  }, [lectureDetails]);

  const onProcessFile = (error: any, file: any) => {
    setFile(file);
    clearErrors("video_link");
    setDisabled(false);
  };

  const onaddfilestart = () => {
    setDisabled(true);
  };

  const onRemoveFile = (error: any, file: any) => {
    setFile("");
    setValue("video_link", "");
  };

  const handleSaveClick = () => {
    if (file) {
      setValue("video_link", JSON.parse(file?.serverId)?.data?.path);
    } else {
      if (isEditMode) {
        if (previousVideo) {
          setValue("video_link", lectureDetails?.video_link);
        } else {
          setValue("video_link", "");
        }
      } else {
        setValue("video_link", "");
      }
    }
    lectureFormRef?.current?.requestSubmit();
  };

  const handleCancelBtn = () => {
    reset();
    setIsEditMode(false);
    setPreviousVideo(null);
    dispatch(setSelectedContentInfo(null));
  };

  const onSubmitLecture = (data: any) => {
    if (isEditMode && lectureDetails) {
      let submitData = {
        ...data,
        chapter_id: lectureDetails?.chapter_id,
        id: lectureDetails?.id,
      };
      if (previousVideo) {
        submitData = { ...submitData, video_link: "" };
      }
      const body = { chapter_id: selectedChapter };
      dispatch(
        updateTutorial(submitData, body, reset, fileUploadRef, setIsEditMode)
      );
    } else {
      if (selectedChapter) {
        const submitData = { ...data, chapter_id: selectedChapter };
        const body = { chapter_id: selectedChapter };
        dispatch(createTutorial(submitData, body, reset, fileUploadRef));
      } else {
        showNotification({
          message: "Select a chapter to upload lecture",
          theme: "light",
          type: "warn",
        });
      }
    }
  };

  return (
    <>
      <form ref={lectureFormRef} onSubmit={handleSubmit(onSubmitLecture)}>
        <div className="form-wrapper">
          <div className="form-group mb-4">
            <h5 className="form-group-label">Title</h5>
            <h6 className="form-group-hint">Enter name of the subject</h6>
            <input
              {...register("name")}
              type="text"
              className={`form-group-control ${
                errors?.name ? "error-border" : ""
              }`}
            />
            <p className="validation-text"> {errors?.name?.message} </p>
          </div>
          <div className="form-group mb-4">
            <h5 className="form-group-label">Short Description</h5>
            <h6 className="form-group-hint">
              Write short description about chapter
            </h6>
            <textarea
              {...register("description")}
              id="chapter_desc"
              rows={5}
              className={`form-group-textarea ${
                errors?.description ? "error-border" : ""
              }`}
            ></textarea>
            <p className="validation-text"> {errors?.description?.message}</p>
          </div>

          {isEditMode && previousVideo ? (
            <button type="button" className="video-card card-lg mb-2 ">
              <div className="d-flex  align-items-center">
                <div className="thumbnail-img">
                  <img
                    src="https://images.unsplash.com/photo-1523961131990-5ea7c61b2107?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8ZGF0YSUyMHNjaWVuY2V8ZW58MHx8MHx8fDA%3D&auto=format&fit=crop&w=500&q=60"
                    alt="video thumbnail"
                  />
                  <div className="dark-overlay"></div>
                  <button type="button" className="play-button">
                    <PlayCircleIcon />
                  </button>
                </div>
                <p className="title">{lectureDetails?.name}</p>
              </div>
              <button
                type="button"
                className="me-2"
                onClick={() => {
                  setPreviousVideo("");
                }}
              >
                <TrashIcon />
              </button>
            </button>
          ) : null}

          {!previousVideo ? (
            <div className="form-group mb-4">
              <h5 className="form-group-label mb-1">Upload lecture video</h5>
              <FilePond
                ref={fileUploadRef}
                allowMultiple={false}
                server={{
                  url: "https://assurance.anasmk.in/api/admin/tutorial/video/store",
                  headers: { Authorization: `Bearer ${accessToken}` },
                }}
                name="video"
                credits={false}
                onprocessfile={onProcessFile}
                onremovefile={onRemoveFile}
                onaddfilestart={onaddfilestart}
                labelFileProcessing=""
                labelTapToCancel=""
                labelFileLoading=""
                allowFileTypeValidation={true}
                labelFileTypeNotAllowed={"File type not supported"}
                acceptedFileTypes={[
                  "video/x-flv",
                  "video/mp4",
                  "video/x-mpegURL",
                  "video/3gpp",
                  "video/x-ms-wmv",
                ]}
              />
              <p className="validation-text"> {errors?.video_link?.message}</p>
            </div>
          ) : null}
          <div className="form-group mb-4">
            <h5 className="form-group-label">Notes</h5>
            <h6 className="form-group-hint">Upload Notes (Optional)</h6>
            <textarea
              {...register("notes")}
              name="notes"
              rows={5}
              className="form-group-textarea"
            ></textarea>
          </div>
        </div>

        <div className="mt-auto">
          <div className="line"></div>
          <div className="d-flex justify-content-between align-items-center inline-cta pt-3">
            <div>
              <div className="form-check">
                {isEditMode ? (
                  <button
                    type="button"
                    className="btn btn-sm btn-outline-primary"
                    disabled={disabled}
                    onClick={() => handleCancelBtn()}
                  >
                    Cancel
                  </button>
                ) : null}
              </div>
            </div>
            <button
              type="button"
              className="btn btn-sm btn-primary"
              disabled={disabled}
              onClick={handleSaveClick}
            >
              Save
            </button>
          </div>
        </div>
      </form>
    </>
  );
});

export default Lecture;
