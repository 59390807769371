import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../redux/hooks";

import "./course.scss";
import { ReactComponent as AddCirclePlusIcon } from "../../../assets/icons/icon-add-circle.svg";
import { ReactComponent as GridPlusIcon } from "../../../assets/icons/icon-grid-plus.svg";
import { ReactComponent as SortIcon } from "../../../assets/icons/icon-sort.svg";
import { ReactComponent as CategoryIcon } from "../../../assets/icons/icon-category.svg";
import { ReactComponent as SearchNormalIcon } from "../../../assets/icons/icon-search-normal.svg";
import { ReactComponent as PaginationLeftArrowIcon } from "../../../assets/icons/pagination-arrow-left.svg";
import { ReactComponent as PaginationRightArrowIcon } from "../../../assets/icons/pagination-arrow-right.svg";

import CourseCard from "./components/CourseCard";
import { getCourseList } from "../../../redux/pages/courseSlice";

const Course = () => {
  //redux
  const dispatch = useDispatch<any>();
  const courseList = useAppSelector((state) => state.course.courseList);
  const [courseListData, setCourseListData] = useState<any>([]);
  const [serachInput, setSearchInput] =useState<any>("")
  const [filteredCourseList, setFilteredCourseList] = useState<any>([]);
  
  const [sortOrder, setSortOrder] = useState("asc");
  const [sortField, setSortField] = useState("name"); 
  const [sortedCourseList, setSortedCourseList] = useState<any>([]);


  const filterCourses = (input: string) => {
    setSearchInput(input);
    const filteredList = courseListData.filter((course: any) =>
      course?.name.toLowerCase().includes(input.toLowerCase())
    );
    setFilteredCourseList(filteredList);
    setSearchInput(input)
  };

  useEffect(() => {
    dispatch(getCourseList());
  }, []);

  useEffect(()=>{
if(courseList){
  setCourseListData(courseList)
}
  },[courseList])

  return (
    <>
      <div className="course-wrapper">
        {/* title section */}
        <div className="d-flex justify-content-between flex-wrap title-wrapper mb-3 mx-4">
          <div>
            <h2 className="title">Courses</h2>
            <p className="desc mb-2">Here you can manage all the courses</p>
          </div>
          <div className="d-flex al gap-3">
          <Link
              to="/courses/manage_subCategory"
              className="btn btn-outline-primary"
            >
              <span className="me-1">
                <GridPlusIcon />
              </span>
              Manage sub-Category
            </Link>
            <Link
              to="/courses/manage_category"
              className="btn btn-outline-primary"
            >
              <span className="me-1">
                <GridPlusIcon />
              </span>
              Manage Category
            </Link>
            <Link to="/courses/manage_course" className="btn btn-primary">
              <span className="me-1">
                <AddCirclePlusIcon />
              </span>
              Create New Course
            </Link>
          </div>
        </div>
        {/* control section */}
        <div className="control-section-wrapper mx-4">
          <div>
            <span className="control-sec-title mb-3">All Courses</span>
          </div>
          <div className="d-flex justify-content-between flex-wrap gap-3  control-wrapper py-3">
            <div className="search-wrapper me-3">
              <div className="search-box">
                <a className="search-icon-button">
                  <SearchNormalIcon />
                </a>
                <input
                  type="text"
                  placeholder="Search course"
                  value={serachInput}
                  onChange={(e)=>filterCourses(e.target.value)}
                  className="search-form-control"
                />
              </div>
            </div>
            <div className="d-flex gap-3">
              <div className="dropdown category-dropdown">
                <button
                  className="control-btn dropdown-toggle"
                  // type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <span className="me-2">
                    <CategoryIcon />
                  </span>
                  Category
                </button>

                <ul className="dropdown-menu">
                  <li>
                    <a className="dropdown-item">
                      Technical
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item">
                      Computer science{" "}
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item">
                      Bio Science
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item">
                      Soft Skills
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item">
                      Cloud Computing
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item">
                      IT & Infrastructure
                    </a>
                  </li>
                </ul>
              </div>
              <div className="dropdown sort-dropdown">
                <button
                  className="control-btn dropdown-toggle"
                  // type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <span className="me-2">
                    <SortIcon />
                  </span>
                  Sort
                </button>

                <ul className="dropdown-menu">
                  <li>
                    <a className="dropdown-item">
                      Name
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item">
                      Created
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item">
                      Last modified
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        {/* card section */}
        <div className="ps-4 pe-3">
          <div className="row g-3">
            {serachInput ===""? courseList.map((item: any, index: any) => (
                <div key={index} className="col-12 col-lg-6 col-xl-4 col-xxl-3">
                  <CourseCard course={item} />
                </div>
              ))
              :filteredCourseList?.map((item:any, index:any) =>(
                <div key={index} className="col-12 col-lg-6 col-xl-4 col-xxl-3">
                  <CourseCard course={item} />
                </div>
              )) }
          </div>
        </div>
        {/* pagination section */}
        {/* <div className="d-flex align-items-center justify-content-center pt-5 pb-3">
          <nav aria-label="...">
            <ul className="pagination mb-0">
              <li className="page-item disabled me-4">
                <a className="page-link">
                  <span className="me-2">
                    <PaginationLeftArrowIcon />
                  </span>
                  Prev
                </a>
              </li>
              <li className="page-item">
                <a className="page-link">
                  1
                </a>
              </li>
              <li className="page-item active" aria-current="page">
                <a className="page-link">2</a>
              </li>
              <li className="page-item">
                <a className="page-link">
                  3
                </a>
              </li>
              <li className="page-item disabled">
                <a className="page-link">
                  ..........
                </a>
              </li>
              <li className="page-item">
                <a className="page-link">
                  5
                </a>
              </li>
              <li className="page-item">
                <a className="page-link">
                  6
                </a>
              </li>
              <li className="page-item">
                <a className="page-link">
                  7
                </a>
              </li>
              <li className="page-item ms-4">
                <a className="page-link">
                  Next
                  <span className="ms-2">
                    <PaginationRightArrowIcon />
                  </span>
                </a>
              </li>
            </ul>
          </nav>
        </div> */}
      </div>
    </>
  );
};

export default Course;
