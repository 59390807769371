import React, { useEffect, useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import { useAppSelector } from "../../redux/hooks";

import ProtectedRoutes from "./ProtectedRoutes";
import RedirectRoute from "./RedirectedRoute";
import Login from "../../views/pre_login/Login/index";
import Dashboard from "../../views/post_login/index";
import Courses from "../../views/post_login/Course/Course";
import CourseDetails from "../../views/post_login/CourseDetails/CourseDetails";
import ManageCategory from "../../views/post_login/category/Category";
import CreateCourse from "../../views/post_login/Course/manage/AddCourse/createCourse";
import Users from "../../views/post_login/users/Users";
import Doubts from "../../views/post_login/doubts/Doubts";
import Offers from "../../views/post_login/offers/Offers";
import Students from "../../views/post_login/students/Students";
import StudentsDetails from "../../views/post_login/studentsDetails/StudentsDetails";
import Overview from "../../views/post_login/overview/Overview";
import Banner from "../../views/post_login/banner-new/Banner";
import Notification from "../../views/post_login/notification/Notification";
import Payments from "../../views/post_login/payments/Payments";
import Settings from "../../views/post_login/settings/Settings";
import ManageSubCategory from "../../views/post_login/subcategory/manage-Subcategory/SubCategory";

const AppRoutes: React.FC = () => {
  const [isLogged, setIsLogged] = useState<boolean>(false);

  const isAuthenticated = useAppSelector(
    (state) => state.login.isAuthenticated
  );

  useEffect(() => {
    if (isAuthenticated) {
      setIsLogged(true);
    } else {
      setIsLogged(false);
    }
  }, [isAuthenticated]);

  return (
    <Routes>
      <Route element={<RedirectRoute isLogged={isLogged} />}>
        <Route path="/login" element={<Login />} />
      </Route>
      <Route element={<ProtectedRoutes isLogged={isLogged} />}>
        <Route path="/" element={<Dashboard />}>
          <Route path="/" element={<Overview />} />
          <Route path="/courses" element={<Courses />} />
          <Route path="/courses/manage_category" element={<ManageCategory />} />
          <Route path="/courses/manage_subCategory" element={<ManageSubCategory/>}/>
          <Route path="/courses/manage_course" element={<CreateCourse />} />
          <Route path="/courses/course_details" element={<CourseDetails />} />
          <Route path="/users" element={<Users />} />
          <Route path="/doubts" element={<Doubts />} />
          <Route path="/offers" element={<Offers />} />
          <Route path="/students" element={<Students />} />
          <Route path="/students/details" element={<StudentsDetails />} />
          <Route path="/banner" element={<Banner />} />
          <Route path="/notifications" element={<Notification />} />
          <Route path="/payments" element={<Payments />} />
          <Route path="/settings" element={<Settings />} />
          {/* If you want to render sidebar and header then add more nested routes as needed */}
        </Route>
      </Route>
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
};

export default AppRoutes;
